import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as ContactIcon } from '../../assets/icons/contact.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-pensil.svg';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty-document.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/property/calendar.svg';
import { ReactComponent as Chart } from '../../assets/icons/property/chart.svg';
import { ReactComponent as Clock } from '../../assets/icons/property/clock.svg';
import { ReactComponent as CompletionIcon } from '../../assets/icons/property/expected-completion.svg';
import { ReactComponent as File } from '../../assets/icons/property/file.svg';
import Schedule from '../../assets/icons/property/schedule.jpg';
import { ReactComponent as Square } from '../../assets/icons/property/square.svg';
import { ReactComponent as Value } from '../../assets/icons/property/value.svg';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { getCurrencySymbol, getDateDifference, getFormattedDate } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { getPropertyEngagements, setPropertyEngageMent } from '../../store/features/propertySlice';
import { Avatar } from '../common/avatar-group';
import Button from '../common/button/button';
import InfiniteScrollComponent from '../common/infinite-scroll';
import InputSearch from '../common/input-search';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import CustomTooltip from '../common/tooltip-new';

export const PropertyEngageMents = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showErrorToast } = useError();

  const pageRef = useRef(null);

  const { propertyDetails } = useSelector(state => state.property);

  const { setSideModal, setModal } = useContext(OrganisationContext);

  const [loading, setLoading] = useState(true);
  const [propertyEngagements, setPropertyEngagements] = useState({ content: [] });
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  // const onAddJob = () => {
  //   setModal({
  //     type: 'add-job',
  //     content: {
  //       property: propertyDetails,
  //       onSuccess: () => fetchPropertyEngageMents(propertyDetails?.id, 0, false, debouncedSearch),
  //     },
  //   });
  // };

  const onAddJob = () => {
    setSideModal({
      type: 'add-new-job',
      content: {
        property: propertyDetails,
        onSuccess: () => fetchPropertyEngageMents(propertyDetails?.id, 0, false, debouncedSearch),
      },
    });
  };

  const fetchPropertyEngageMents = (property_id, page, merge, debouncedSearch, showLoading = true) => {
    pageRef.current = page;
    if (showLoading && !merge) {
      setLoading(true);
    }
    dispatch(
      getPropertyEngagements({
        property_id: property_id,
        merge,
        params: {
          page: page,
          size: 20,
          search: debouncedSearch,
          engagement_type: 'JOB',
        },
      }),
    )
      .then(data => {
        if (merge) {
          setPropertyEngagements({ ...data, content: [...propertyEngagements.content, ...data.content] });
        } else {
          setPropertyEngagements(data);
        }
        pageRef.current = page;
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_JOBS') }))
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    fetchPropertyEngageMents(propertyDetails?.id, pageRef.current + 1, true, debouncedSearch, false);
  };

  useEffect(() => {
    if (propertyDetails?.id) {
      fetchPropertyEngageMents(propertyDetails?.id, 0, false, debouncedSearch);
    }

    return () => {
      setPropertyEngageMent([]);
    };
  }, [propertyDetails?.id, debouncedSearch]);

  const onStageClick = (workitems, job) => {
    if (workitems?.length === 1) {
      const workitem = workitems?.[0] || {};
      navigate(`/properties/property/${propertyDetails?.id}/workitems/${workitem?.id}`);
    } else {
      setModal({
        type: 'redirect-to-stage',
        content: {
          jobDetails: job,
          propertyDetails: propertyDetails,
        },
      });
    }
  };

  const getStageNameOfJob = workitems => {
    const workitem = workitems?.[0] || {};
    const workitemLength = workitems?.length || 0;
    const stageName = workitem?.stage?.name || null;
    return stageName ? `${workitem?.stage?.name}${workitemLength > 1 ? ` + ${workitemLength}` : ''}` : null;
  };

  const jobItem = job => {
    const { id: jobId, name, contact, project, engagement_value, engagement_dates, workitems, created_by } = job || {};
    const { expected_completion, deadline, created_date } = engagement_dates || {};
    const { contracted_amount, currency } = engagement_value || {};

    const firstWorkitem = workitems?.[0] || {};

    return (
      <div className="flex-column p-4 pb-3 border box-card" key={jobId} onClick={() => onStageClick(workitems, job)}>
        <div className="flex items-center justify-between pb-3 border-bottom">
          <label className="inter-500-text natural-900-text flex-1 line-height-20 font-14">{name}</label>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-1">
              <CustomTooltip
                wrapperClassName="job-tooltip"
                id={`${jobId}-assignee`}
                place="bottom"
                noArrow={true}
                content={<label className="inter-500-text natural-700-text font-12">{t('ASSIGNEE')}</label>}>
                <p className="inter-400-text font-12 natural-400-text line-height-20">{t('ASSIGNEE')}:</p>
              </CustomTooltip>
              <Avatar variant="medium" avatar={contact} backgroundColorIndex={0} />
            </div>

            <div className="flex gap-1">
              <CustomTooltip
                wrapperClassName="job-tooltip"
                id={`${jobId}-stage`}
                place="bottom"
                noArrow={true}
                hidden={!firstWorkitem?.workflow?.arrived}
                content={
                  <div className="flex-column row-gap-1">
                    <label className="inter-500-text natural-700-text font-12">{t('DAYS_IN_THIS_PROCESS')}</label>
                    <label>
                      <span className="inter-400-text natural-400-text font-12">
                        {getFormattedDate(firstWorkitem?.workflow?.arrived, 'DD/MM/YY')}
                      </span>{' '}
                      : <span className="inter-400-text natural-700-text font-12">{firstWorkitem?.workflow?.name}</span>
                    </label>
                  </div>
                }>
                <Chart className="orange-500-text mr-1" />
              </CustomTooltip>
              <label className="flex items-center inter-400-text natural-900-text line-height-20 font-14">
                {getDateDifference(firstWorkitem?.workflow?.arrived, null, 'days')}
              </label>
            </div>
            <div className="flex gap-1">
              <CustomTooltip
                wrapperClassName="job-tooltip"
                id={`${jobId}-workflow`}
                place="bottom"
                noArrow={true}
                hidden={!firstWorkitem?.stage?.arrived}
                content={
                  <div className="flex-column row-gap-1">
                    <label className="inter-500-text natural-700-text font-12">{t('DAYS_IN_THIS_STAGE')}</label>
                    <label>
                      <span className="inter-400-text natural-400-text font-12">
                        {getFormattedDate(firstWorkitem?.stage?.arrived, 'DD/MM/YY')}
                      </span>{' '}
                      : <span className="inter-400-text natural-700-text font-12">{firstWorkitem?.stage?.name}</span>
                    </label>
                  </div>
                }>
                <div className="flex items-center mr-2">
                  <Square className="success-500-text" />
                  <label className="flex items-center inter-400-text natural-900-text line-height-20 font-14">
                    {getDateDifference(firstWorkitem?.stage?.arrived, null, 'days')}
                  </label>
                </div>
              </CustomTooltip>
            </div>
            {getStageNameOfJob(workitems) && (
              <div className="flex gap-1 cursor text-ellipsis">
                <label className="color-purple bg-pink px-3 py-0_5 radius-6 inter-500-text font-12 line-height-20 text-ellipsis">
                  {getStageNameOfJob(workitems)}
                </label>
              </div>
            )}
            <div className="flex gap-1">
              <img src={Schedule} alt="icon" />
            </div>
          </div>
        </div>
        <div className="flex-column row-gap-2 pt-3">
          <div className="flex items-center col-gap-5 ">
            <div className="flex gap-1 items-center">
              <Value width={14} height={14} />
              <span className="inter-400-text font-12 natural-400-text line-height-20">{t('VALUE')}:</span>
              <span className="inter-400-text font-12 natural-900-text line-height-20">
                {contracted_amount ? `${getCurrencySymbol(currency)}${contracted_amount}` : '-'}
              </span>
            </div>
            <div className="flex gap-1 items-center">
              <ContactIcon width={14} height={14} className="natural-400-text" />
              <span className="inter-400-text font-12 natural-400-text line-height-20">{t('CONTACT')}:</span>
              <span className="inter-400-text font-12 natural-900-text line-height-20">{contact.name}</span>
            </div>
            <div className="flex gap-1 items-center">
              <File width={14} height={14} />
              <span className="inter-400-text font-12 natural-400-text line-height-20">{t('PROJECT')}:</span>
              <span className="inter-400-text font-12 natural-900-text line-height-20">
                {project ? project.name : '-'}
              </span>
            </div>
          </div>
          <div className="flex items-center col-gap-5 ">
            <div className="flex gap-1 items-center">
              <CustomTooltip
                wrapperClassName="job-tooltip flex items-center gap-1"
                id={`${jobId}-expected-completion`}
                place="bottom"
                noArrow={true}
                content={<label className="inter-500-text natural-700-text font-12">{t('EXPECTED_COMPLETION')}</label>}>
                <CompletionIcon width={14} height={14} className="natural-400-text" />
                <p className="inter-400-text font-12 natural-400-text line-height-20">{t('EXPECTED_COMPLETION')}:</p>
              </CustomTooltip>
              <span className="inter-400-text font-12 natural-900-text line-height-20 font-14">
                {expected_completion ? moment.unix(expected_completion).format('DD/MM/YY') : '-'}
              </span>
            </div>
            <div className="flex gap-1">
              <CustomTooltip
                wrapperClassName="job-tooltip flex items-center gap-1"
                id={`${jobId}-deadline`}
                place="bottom"
                noArrow={true}
                content={<label className="inter-500-text natural-700-text font-12">{t('DEADLINE')}</label>}>
                <Clock width={14} height={14} />
                <p className="inter-400-text font-12 natural-400-text line-height-20">{t('DEADLINE')}:</p>
              </CustomTooltip>
              <span className="inter-400-text font-12 natural-900-text line-height-20 font-14">
                {deadline ? moment.unix(deadline).format('DD/MM/YY') : '-'}
              </span>
            </div>

            <div className="flex gap-1 items-center">
              <CalendarIcon width={14} height={14} className="natural-400-text" />
              <span className="inter-400-text font-12 natural-400-text line-height-20">{t('CREATED_ON')}:</span>
              <span className="inter-400-text font-12 natural-900-text line-height-20">
                {created_date ? moment.unix(created_date).format('DD/MM/YY') : '-'}
              </span>
            </div>
            <div className="flex gap-1 items-center">
              <EditIcon width={14} height={14} className="grey-text-04" />
              <span className="inter-400-text font-12 natural-400-text line-height-20">{t('CREATED_BY')}:</span>
              <Avatar variant="medium" avatar={created_by} backgroundColorIndex={3} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="pxy-6 flex-column row-gap-6 overflow-hidden h-full">
      <div className="flex justify-between">
        <div className="flex align-center">
          <InputSearch placeholder={'Search'} value={search} onChange={setSearch} />
        </div>
        <Button
          fontSize="14px"
          size="average"
          width="120px"
          borderRadius="100px"
          className="primary specified-width  px-4 py-2_5 ml-3"
          afterIcon={<AddIcon className="after-icon white-text" height={16} width={16} />}
          label={'Add Job'}
          onClick={onAddJob}
        />
      </div>
      <div className="mb-2 flex-1 overflow-scroll" id="custom-scrollableDiv">
        <SkeletonTransition
          loading={loading}
          loaderClassName="item w-full flex items-center justify-center flex-1"
          height={'500px'}
          containerClassName="line-height-1 flex-1 h-full radius-2"
          baseColor="#CDCDCD"
          highlightColor="#E8E8E8">
          {
            <motion.div
              key={'job-data'}
              initial={{ opacity: 0.3, y: 10 }}
              animate={{ opacity: 1, y: 0, transition: { duration: 0.9 }, height: '100%' }}>
              {propertyEngagements?.content.length > 0 ? (
                <InfiniteScrollComponent
                  dataLength={propertyEngagements?.content.length}
                  hasMore={!propertyEngagements?.last}
                  fetchMoreData={fetchMoreData}
                  infiniteScrollClassName={'h-full'}
                  scrollableTarget="custom-scrollableDiv">
                  <div className="flex-column row-gap-5">
                    {(propertyEngagements?.content || []).map(job => jobItem(job))}
                  </div>
                </InfiniteScrollComponent>
              ) : (
                <NoData
                  title={t('NO_JOBS')}
                  description={t('NO_JOBS_HERE')}
                  className={'h-full w-full'}
                  EmptyIcon={EmptyIcon}
                  iconClassName="relative"
                />
              )}
            </motion.div>
          }
        </SkeletonTransition>
      </div>
    </div>
  );
};
