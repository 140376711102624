import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { Fragment, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { components } from 'react-select';
import MaskedInput from 'react-text-mask';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as RightIcon } from '../../assets/images/arrow-right-black.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/toast-close.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { createNumberMask } from '../../helpers/createNumberMask';
import {
  getContactValueByType,
  getFormattedNumber,
  getFormattedNumberStyle,
  getProductPrice,
} from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { getProductList, getSuppliersList, getTaxationSchemes } from '../../store/features/quotesSlice';
import IconContainer from '../common/icon-container';
import SearchableDropdown from '../common/searchable-dropdown';
import CustomTooltip from '../common/tooltip-new';
import { QuoteDetailsProductItemWrapper } from './wrapper';

const defaultNumberMaskOptions = {
  prefix: '£',
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowLeadingZeroes: true,
};

const CustomProductOption = props => {
  const { innerProps, data, isSelected, isFocused } = props;
  const { name, description } = data;

  return (
    <components.Option {...props} getStyles={() => {}}>
      <div
        className={classNames(
          'flex items-center w-full px-4 py-3 cursor border-bottom option-wrapper',
          isSelected && 'selected',
          isFocused && 'bg-lightgray-1',
        )}
        {...innerProps}>
        <div className="flex-column row-gap-1 w-full">
          <div className="flex items-center justify-between col-gap-2">
            <label className="inter-400-text natural-900-text option-text one-line">{name}</label>
            <label className="inter-400-text natural-900-text nowrap">{getProductPrice(data)}</label>
          </div>
          <label className="inter-400-text natural-500-text font-12">{description}</label>
        </div>
      </div>
    </components.Option>
  );
};

const CustomMenu = props => {
  const { selectProps, children } = props;
  const { productFamilies, selectedProductFamily, setSelectedProductFamily } = selectProps;

  return (
    <components.Menu {...props}>
      <CustomMenuWrapper className="flex">
        <div className="flex-column items-center family-wrapper border-right">
          {productFamilies?.map(family => (
            <div
              key={family.id}
              className={classNames(
                'flex items-center w-full pxy-3 col-gap-2 cursor border-bottom',
                family.id === selectedProductFamily?.id && 'selected',
              )}
              tabIndex={1}
              onClick={() => setSelectedProductFamily(family)}>
              <img
                src={family.id === selectedProductFamily?.id ? family?.icon?.active : family.icon?.inactive}
                alt="family"
                height={24}
                width={24}
              />
              <label
                className={classNames(
                  'inter-500-text one-line',
                  family.id === selectedProductFamily?.id ? 'primary-500-text' : 'natural-700-text',
                )}>
                {family.name}
              </label>
            </div>
          ))}
        </div>
        <div className="flex-column flex-1 h-full overflow-hidden">{children}</div>
      </CustomMenuWrapper>
    </components.Menu>
  );
};

const CustomSingleValue = props => {
  const { selectProps } = props;
  const { menuIsOpen } = selectProps;
  if (menuIsOpen) {
    return <Fragment />;
  }
  return <components.SingleValue {...props} />;
};

const CustomSupplierOption = props => {
  const { t } = useTranslation();
  const { innerProps, data, isSelected, isFocused } = props;
  const { label, image, contacts } = data;

  const email = getContactValueByType(contacts, 'contact_type', 'EMAIL', 'contact_value');

  return (
    <components.Option {...props} getStyles={() => {}}>
      <div
        className={classNames(
          'flex items-center w-full px-4 py-3 cursor border-bottom option-wrapper',
          isSelected && 'selected',
          isFocused && 'bg-lightgray-1',
        )}
        {...innerProps}>
        <div className="flex flex-1 items-center justify-between col-gap-2 overflow-hidden">
          {image?.url && (
            <div className="w-fit-content flex">
              <img src={image?.url} alt="supplier" height={40} width={40} style={{ aspectRatio: '1' }} />
            </div>
          )}
          <div className="flex-column row-gap-1 flex-1 overflow-hidden">
            <label className="inter-400-text natural-900-text option-text one-line">{label}</label>
            <label className={'inter-400-text natural-500-text font-12 one-line'}>{email || t('NO_EMAIL')}</label>
          </div>
        </div>
      </div>
    </components.Option>
  );
};

const QuoteSupplier = ({ isDisabled = false, productId, supplier, onSupplierUpdate = () => {} }) => {
  const { name, id } = supplier || {};
  const { t } = useTranslation();

  const [searchedSupplier, setSearchedSupplier] = useState('');

  const supplierName = id ? `${t('SUPPLIER')}: ${name}` : `${t('SUPPLIER')}: No Supplier`;

  return (
    <SearchableDropdown
      key={`supplier-${productId}`}
      inputValue={searchedSupplier}
      onInputChange={setSearchedSupplier}
      value={{ value: id, label: supplierName }}
      placeholder={supplierName}
      className="quote-supplier-dropdown"
      isSearchable={false}
      isCustomSearchable={false}
      isDisabled={!productId || isDisabled}
      ignoreMenuDomClick={true}
      menuPortalTarget={document.getElementById('quote-wrapper')}
      onChange={option => {
        onSupplierUpdate(option);
      }}
      defaultAdditional={{
        page: 0,
        fetchFunction: getSuppliersList,
        pageable: false,
        payload: { product_id: productId },
        formatOptions: suppliers => {
          const pricesFlatMap = suppliers?.flatMap(supplier => supplier?.prices || []) || [];
          return pricesFlatMap.map(priceData => {
            const { merchant, cost, taxation_scheme, price, id: priceId } = priceData || {};
            const { name, id } = merchant || {};
            return {
              label: name,
              value: id,
              ...merchant,
              price,
              cost,
              taxation_scheme,
              priceId,
            };
          });
        },
      }}
      customStyle={{
        menu: { borderRadius: 6 },
        control: {
          height: 26,
          minHeight: 26,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          boxShadow: `none`,
          '&:hover': {
            borderColor: 'none',
          },
        },
        valueContainer: { height: 26, padding: '0 8px' },
        indicatorContainer: {
          height: 16,
          width: 16,
        },
        dropdownIndicator: {
          padding: 0,
          height: 16,
          width: 16,
        },
        loadingIndicator: {
          display: 'none',
        },
      }}
      customComponent={{ Option: CustomSupplierOption }}
    />
  );
};

const QuoteDetailsProductItem = ({
  product,
  onRemoveProduct,
  isQuoteEditable,
  onSaveQuoteProduct,
  notEditableQuoteInfo = '',
  integration,
  costOpened,
}) => {
  const theme = useTheme();

  const { showErrorToast } = useError();

  const { t } = useTranslation();

  const { hasPermission } = useContext(OrganisationContext);

  const { productFamilies } = useSelector(state => state.quotes);
  const { jobDetails } = useSelector(state => state.property);
  const { job_type } = jobDetails || {};
  const { quote_category } = job_type || {};

  const {
    name,
    qty,
    unit_price,
    discount_rate,
    taxation_scheme,
    total_amount,
    product: selectedProduct,
    description,
    line_item_type,
    isNew,
    components,
    cost_unit_price,
    cost_discount_rate,
    cost_taxation_scheme,
    cost_amount,
  } = product || {};

  const productFamilyWithTag = productFamilies?.find(family => family.tag);
  const productProfit = total_amount - cost_amount;
  const productProfitPercentage = ((productProfit || 0) / (total_amount || 1)) * 100;

  const [editKey, setEditKey] = useState(null);
  const [searchedProduct, setSearchedProduct] = useState('');
  const [searchedTaxRate, setSearchedTaxRate] = useState('');
  const [selectedProductFamily, setSelectedProductFamily] = useState(productFamilyWithTag);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const isComponentsAvailable = components?.length > 0;

  const textareaRef = useRef(null);

  const updateTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  };

  // TODO: This is temporary solution to fix the issue of textarea height
  // Need to find a better solution
  useLayoutEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      updateTextareaHeight();
    }
  }, [textareaRef.current, editKey, product.description]);

  useEffect(() => {
    if (isNew) {
      if (line_item_type === 'TIME' || line_item_type === 'OTHER') {
        setEditKey('DESCRIPTION');
      } else if (line_item_type === 'CATALOG') {
        setEditKey('NAME');
      }
    }
  }, [line_item_type, isNew]);

  const onNumericInput = input => {
    const inputValue = input?.replaceAll(',', '');
    return Math.abs(parseFloat(inputValue ? inputValue : '0'));
  };

  const calculateUnitPriceFromMargin = (margin, costUnitPrice) => {
    return costUnitPrice / (1 - margin / 100);
  };

  const onUpdateProduct = (key, value, item) => {
    const { discount_rate, unit_price, qty, cost_unit_price = 0, cost_discount_rate = 0 } = item || {};
    const productQty = key === 'qty' ? onNumericInput(value) : qty;
    const productUnitPrice = key === 'unit_price' ? onNumericInput(value) : unit_price;
    const productDiscountRate = key === 'discount_rate' ? onNumericInput(value) : discount_rate;

    const costProductUnitPrice = key === 'cost_unit_price' ? onNumericInput(value) : cost_unit_price;
    const costProductDiscountRate = key === 'cost_discount_rate' ? onNumericInput(value) : cost_discount_rate;

    if (key === 'product_profit_percentage') {
      const cost_amount = productQty * costProductUnitPrice * (1 - costProductDiscountRate / 100);
      const newProductTotalAmount = calculateUnitPriceFromMargin(onNumericInput(value), cost_amount);
      const newProductTotalNet = newProductTotalAmount / (1 - productDiscountRate / 100);
      const newProductUnitPrice = newProductTotalNet / productQty;
      onSaveQuoteProduct({
        ...item,
        unit_price: newProductUnitPrice,
        total_net: newProductTotalNet,
        total_amount: newProductTotalAmount,
      });
      return null;
    }

    if (key === 'cost_unit_price' || key === 'cost_discount_rate') {
      const cost_amount = productQty * costProductUnitPrice * (1 - costProductDiscountRate / 100);
      const newProductTotalAmount = calculateUnitPriceFromMargin(productProfitPercentage, cost_amount);
      const newProductTotalNet = newProductTotalAmount / (1 - productDiscountRate / 100);
      const newProductUnitPrice = newProductTotalNet / productQty;

      onSaveQuoteProduct({
        ...item,
        [key]: value,
        cost_net: productQty * costProductUnitPrice,
        cost_amount: cost_amount,
        unit_price: newProductUnitPrice,
        total_net: newProductTotalNet,
        total_amount: newProductTotalAmount,
      });
      return null;
    }

    const newProduct =
      key === 'product'
        ? {
            id: item.id,
            isNew: item.isNew,
            ...value,
            total_net: (value.unit_price || 0) * Number(qty),
            total_amount: Number(qty) * value.unit_price * (1 - value.discount_rate / 100),
          }
        : {
            ...item,
            [key]: value,
            total_net: productQty * productUnitPrice,
            total_amount: productQty * productUnitPrice * (1 - productDiscountRate / 100),
            cost_net: productQty * costProductUnitPrice,
            cost_amount: productQty * costProductUnitPrice * (1 - costProductDiscountRate / 100),
          };
    onSaveQuoteProduct(newProduct);
  };

  const onSearchProduct = (search, action) => {
    if (!action) return;
    if (action.action === 'input-change') {
      setSearchedProduct(search);
      return;
    }
    if (action.action === 'set-value') {
      setSearchedProduct('');
      return;
    }
    if (action.action === 'input-blur') {
      if (action.prevInputValue) {
        onUpdateProduct(
          'product',
          {
            ...product,
            sku: '',
            name: action.prevInputValue,
            description: '',
            unit_price: 0,
            product: null,
          },
          product,
        );
      }
      setSearchedProduct('');
    }
  };

  const onSupplierUpdate = supplier => {
    if (supplier) {
      const { value, label, price: unit_price, cost, taxation_scheme } = supplier;
      const { price: costPrice, taxation_scheme: costTaxationScheme } = cost || {};

      const newProduct = {
        ...product,
        supplier: { id: value, name: label },
        cost_unit_price: costPrice,
        cost_discount_rate: 0,
        cost_taxation_scheme: costTaxationScheme,
        unit_price,
        discount_rate: 0,
        total_net: unit_price * qty,
        total_amount: unit_price * qty,
        taxation_scheme,
        cost_net: costPrice * qty,
        cost_amount: costPrice * qty,
      };
      onSaveQuoteProduct(newProduct);
    }
  };

  const onProductSelect = productOption => {
    const { sku, name, product_type, description, id, sold_as, pricing, integration } = productOption || {};
    const pricesFlatMap = pricing.flatMap(price => price.prices);
    const productPrice = pricesFlatMap.find(price => price?.price_type === 'COG') || pricesFlatMap?.at(0) || {};

    const { price: unit_price, taxation_scheme, cost, merchant } = productPrice || {};
    const { price: cost_unit_price = 0, taxation_scheme: cost_taxation_scheme = 0 } = cost || {};
    onUpdateProduct(
      'product',
      {
        ...product,
        sku,
        name,
        product_type,
        description,
        sold_as,
        supplier: merchant?.id ? { id: merchant.id, name: merchant.name } : null,
        unit_price: unit_price || 0,
        product: { id },
        integration,
        taxation_scheme,
        cost_unit_price: parseFloat(cost_unit_price || 0).toFixed(2),
        cost_taxation_scheme,
        cost_net: cost_unit_price * qty,
        cost_amount: cost_unit_price * qty,
      },
      product,
    );
  };

  const editProductName = () => {
    return (
      <div className="flex h-full items-start w-full">
        <SearchableDropdown
          key={quote_category ? selectedProductFamily?.id : 'all'}
          inputValue={searchedProduct}
          onInputChange={onSearchProduct}
          placeholder="Select"
          autoFocus
          openMenuOnFocus
          isSearchable={true}
          isCustomSearchable={!!quote_category}
          onClose={() => setEditKey(null)}
          menuPortalTarget={document.getElementById('quote-wrapper')}
          value={selectedProduct ? { value: selectedProduct?.id, label: name } : null}
          onChange={option => {
            onProductSelect(option);
            setEditKey(null);
          }}
          defaultAdditional={{
            page: 0,
            fetchFunction: getProductList,
            pageable: true,
            params: {
              include_pricing: true,
              family_id: quote_category ? selectedProductFamily?.id : null,
            },
          }}
          customStyle={{
            menu: { width: 628 },
            menuList: { maxHeight: 350 },
            control: {
              height: 26,
              minHeight: 26,
              backgroundColor: theme.natural_100,
              borderColor: theme.natural_200,
              boxShadow: `0px 0px 0px 4px ${theme.focus_border}`,
              '&:hover': {
                borderColor: 'none',
              },
            },
            valueContainer: { height: 26, padding: '0 8px' },
            indicatorContainer: {
              height: 16,
              width: 16,
            },
            dropdownIndicator: {
              padding: 0,
              height: 16,
              width: 16,
            },
            loadingIndicator: {
              display: 'none',
            },
            menuPortal: { zIndex: 9999 },
          }}
          productFamilies={productFamilies?.filter(family => family.tag)}
          selectedProductFamily={selectedProductFamily}
          setSelectedProductFamily={setSelectedProductFamily}
          customComponent={{
            Option: CustomProductOption,
            SingleValue: CustomSingleValue,
            ...(quote_category && { Menu: CustomMenu }),
          }}
        />
      </div>
    );
  };

  const handleEnterKeyPress = event => {
    if (event.keyCode === 13 && !event.shiftKey) {
      setEditKey(null);
    }
  };

  const handleInputFocus = event => {
    setTimeout(() => {
      event.target.select();
    }, 0);
  };

  const editContent = (key, value, type = 'number', numberMaskOptions, placeholder) => {
    return (
      <div
        className="flex-column items-center row-gap-1 w-full input-container relative"
        onBlur={() => setEditKey(null)}>
        {type === 'textarea' ? (
          <textarea
            value={value}
            className="inter-400-text natural-900-text input textarea w-full py-1 line-height-20"
            id={`inpId-${product.id}`}
            ref={textareaRef}
            placeholder={placeholder}
            autoFocus
            onFocus={handleInputFocus}
            onKeyDown={handleEnterKeyPress}
            onChange={({ target: { value } }) => onUpdateProduct(key, value, product)}
          />
        ) : type === 'text' ? (
          <input
            className="inter-400-text natural-900-text one-line input w-full"
            value={value}
            autoFocus
            id={`inpId-${product.id}`}
            placeholder={placeholder}
            onFocus={handleInputFocus}
            onKeyDown={handleEnterKeyPress}
            onChange={({ target: { value } }) => onUpdateProduct(key, value, product)}
          />
        ) : (
          <MaskedInput
            mask={createNumberMask(numberMaskOptions)}
            value={value}
            className="inter-400-text natural-900-text one-line input w-full"
            placeholder={placeholder}
            guide={false}
            autoFocus
            onKeyDown={handleEnterKeyPress}
            onFocus={handleInputFocus}
            onChange={({ target: { value } }) => {
              let newValue = value
                ?.replaceAll(numberMaskOptions?.prefix, '')
                ?.replaceAll(numberMaskOptions?.suffix, '');
              if (key === 'discount_rate' && parseFloat(newValue) > 99) {
                showErrorToast({ default_message: t('DISCOUNT_BELOW_QUOTE') });
                newValue = newValue.slice(0, 2);
              }
              onUpdateProduct(key, newValue, product);
            }}
          />
        )}
      </div>
    );
  };

  const editProfitPercentage = () => {
    return (
      <div
        className="flex-column items-center row-gap-1 w-full input-container relative"
        onBlur={() => setEditKey(null)}>
        <MaskedInput
          mask={createNumberMask({ ...defaultNumberMaskOptions, prefix: '', suffix: '%', integerLimit: 3 })}
          defaultValue={productProfitPercentage}
          className="inter-400-text natural-900-text one-line input w-full"
          placeholder="00.00%"
          guide={false}
          autoFocus
          onKeyDown={handleEnterKeyPress}
          onFocus={handleInputFocus}
          onChange={({ target: { value } }) => {
            let newValue = value
              ?.replaceAll('%', '')
              ?.replaceAll(defaultNumberMaskOptions?.prefix, '')
              ?.replaceAll(defaultNumberMaskOptions?.suffix, '');
            if (parseFloat(newValue) > 99) {
              showErrorToast({ default_message: t('MARGIN_BELOW_QUOTE') });
              newValue = newValue.slice(0, 2);
            }
            onUpdateProduct('product_profit_percentage', newValue, product);
          }}
        />
      </div>
    );
  };

  const editProductTax = (key, value) => {
    return (
      <div className="flex h-full items-start w-full">
        <SearchableDropdown
          inputValue={searchedTaxRate}
          onInputChange={setSearchedTaxRate}
          placeholder={t('SELECT')}
          autoFocus
          openMenuOnFocus
          isCustomSearchable={false}
          onBlur={() => setEditKey(null)}
          menuPortalTarget={document.getElementById('quote-wrapper')}
          value={value ? { value: value?.id, label: value?.name } : null}
          onChange={option => {
            onUpdateProduct(key, option, product);
            setEditKey(null);
          }}
          defaultAdditional={{
            page: 0,
            fetchFunction: getTaxationSchemes,
            pageable: false,
            params: {
              integration_id: integration?.id || null,
              ...(integration?.id && { use_connector: true }),
            },
          }}
          customStyle={{
            menu: { width: 200, borderRadius: 6 },
            control: {
              height: 26,
              minHeight: 26,
              backgroundColor: theme.natural_100,
              borderColor: theme.natural_200,
              boxShadow: `0px 0px 0px 4px ${theme.focus_border}`,
              '&:hover': {
                borderColor: 'none',
              },
            },
            valueContainer: { height: 26, padding: '0 8px' },
            indicatorContainer: {
              height: 16,
              width: 16,
            },
            dropdownIndicator: {
              padding: 0,
              height: 16,
              width: 16,
            },
            loadingIndicator: {
              display: 'none',
            },
          }}
          customComponent={{ SingleValue: CustomSingleValue }}
        />
      </div>
    );
  };

  const renderItem = (
    value,
    key,
    isEditable = true,
    valueClassName = '',
    containerClassname = '',
    wrapperClassName = '',
  ) => {
    return (
      <CustomTooltip
        id={`quote-tooltip-${key}`}
        wrapperClassName={classNames('flex', wrapperClassName)}
        place="bottom-start"
        hidden={isQuoteEditable}
        content={<label className="flex col-gap-1 items-center">{notEditableQuoteInfo}</label>}>
        <div
          className={classNames(
            'flex-column py-0_5 w-full',
            containerClassname,
            isQuoteEditable && isEditable && 'cursor product-detail-item px-2 w-full',
            key === 'COMPONENT-QTY' ? 'justify-start' : 'justify-center',
          )}
          onClick={() => {
            if (isQuoteEditable && isEditable) {
              setEditKey(key);
            }
          }}>
          <label
            tabIndex={isEditable ? '0' : '-1'}
            onFocus={() => isQuoteEditable && isEditable && setEditKey(key)}
            className={classNames('inter-400-text natural-900-text word-break', valueClassName)}>
            {value}
          </label>
        </div>
      </CustomTooltip>
    );
  };

  return (
    <div className="border-bottom">
      <QuoteDetailsProductItemWrapper className="data-container items-start w-full ">
        {editKey === 'NAME' && line_item_type === 'CATALOG'
          ? editProductName()
          : editKey === 'NAME' && line_item_type !== 'CATALOG'
          ? editContent('name', name, 'text')
          : renderItem(name, 'NAME', true, '', '', 'row-span-2')}
        {editKey === 'DESCRIPTION'
          ? editContent('description', description, 'textarea', { prefix: '' })
          : renderItem(description, 'DESCRIPTION', true, '', '', 'row-span-2')}
        {editKey === 'QTY'
          ? editContent('qty', qty, 'number', { prefix: '' })
          : renderItem(qty, 'QTY', true, 'text-center', '', 'row-span-2')}
        {editKey === 'UNIT_PRICE'
          ? editContent('unit_price', unit_price, 'number', defaultNumberMaskOptions, '£00.00')
          : renderItem(getFormattedNumber(unit_price), 'UNIT_PRICE', true, 'text-center')}
        {editKey === 'DISCOUNT'
          ? editContent(
              'discount_rate',
              discount_rate,
              'number',
              { ...defaultNumberMaskOptions, prefix: '', suffix: '%', integerLimit: 3 },
              '00.00%',
            )
          : renderItem(getFormattedNumberStyle(discount_rate / 100, 'percent'), 'DISCOUNT', true, 'text-center')}
        {editKey === 'TAX'
          ? editProductTax('taxation_scheme', taxation_scheme)
          : renderItem(
              getFormattedNumberStyle((taxation_scheme?.rate || 0) / 100, 'percent'),
              'TAX',
              true,
              'text-center',
            )}
        {renderItem(getFormattedNumber(total_amount || 0), 'AMOUNT', false, 'text-center')}
        <div className="flex items-center justify-evenly">
          {isComponentsAvailable ? (
            <IconContainer
              Icon={RightIcon}
              iconColor="natural_900"
              iconClassName={classNames('cursor', { 'rotate-270': !isCollapsed }, { 'rotate-90': isCollapsed })}
              backgroundColor="transparent"
              iconContainerClassname="p-0 product-child-toggle"
              onClick={() => setIsCollapsed(prev => !prev)}
            />
          ) : null}
          {renderItem(
            <IconContainer
              Icon={CloseIcon}
              iconColor="natural_900"
              iconClassName="cursor"
              backgroundColor="transparent"
              iconContainerClassname="p-0 remove-product"
              onClick={() => onRemoveProduct(product)}
            />,
            '',
            false,
          )}
        </div>
        <AnimatePresence>
          {costOpened ? (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="cost-wrapper border radius-2">
              <div className="flex items-center justify-between px-4 py-2 border-bottom">
                <label className="inter-600-text natural-700-text font-12 letter-spacing-1">COST PRICES</label>
                <QuoteSupplier
                  productId={product?.product?.id}
                  supplier={product.supplier}
                  onSupplierUpdate={onSupplierUpdate}
                  isDisabled={!isQuoteEditable || !hasPermission('QUOTE_COST_MANAGE') || line_item_type !== 'CATALOG'}
                />
              </div>
              <div className="cost-container pt-2 pb-3 pr-4">
                {editKey === 'COST_UNIT_PRICE'
                  ? editContent('cost_unit_price', cost_unit_price, 'number', defaultNumberMaskOptions, '£00.00')
                  : renderItem(
                      getFormattedNumber(cost_unit_price || 0),
                      'COST_UNIT_PRICE',
                      hasPermission('QUOTE_COST_MANAGE'),
                      'text-center',
                    )}
                {editKey === 'COST_DISCOUNT'
                  ? editContent(
                      'cost_discount_rate',
                      cost_discount_rate,
                      'number',
                      { ...defaultNumberMaskOptions, prefix: '', suffix: '%', integerLimit: 3 },
                      '00.00%',
                    )
                  : renderItem(
                      getFormattedNumberStyle((cost_discount_rate || 0) / 100, 'percent'),
                      'COST_DISCOUNT',
                      hasPermission('QUOTE_COST_MANAGE'),
                      'text-center',
                    )}
                {editKey === 'COST_TAX'
                  ? editProductTax('cost_taxation_scheme', cost_taxation_scheme)
                  : renderItem(
                      getFormattedNumberStyle((cost_taxation_scheme?.rate || 0) / 100, 'percent'),
                      'COST_TAX',
                      hasPermission('QUOTE_COST_MANAGE'),
                      'text-center',
                    )}
                {renderItem(getFormattedNumber(cost_amount || 0), 'AMOUNT', false, 'text-center')}
                <div className="cost-profit-container bg-natural-100">
                  <label className="inter-400-text natural-900-text text-center">Profit</label>
                  <label className="inter-500-text natural-900-text text-center">
                    {getFormattedNumber(productProfit || 0)}
                  </label>
                  {/* <label className="inter-500-text natural-900-text text-center">
                    {getFormattedNumberStyle(productProfitPercentage / 100, 'percent')}
                  </label> */}
                  {editKey === 'PROFIT_PERCENTAGE'
                    ? editProfitPercentage()
                    : renderItem(
                        getFormattedNumberStyle((productProfitPercentage || 0) / 100, 'percent'),
                        'PROFIT_PERCENTAGE',
                        true,
                        'text-center',
                      )}
                </div>
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </QuoteDetailsProductItemWrapper>
      <AnimatePresence>
        {isComponentsAvailable && !isCollapsed && (
          <motion.div
            className="flex-column row-gap-4 mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}>
            {components.map((component, index) => (
              <div className="components-container radius-1_5 bg-natural-100 my-0" key={index}>
                {renderItem(component.name, 'COMPONENT-NAME', false, 'px-3 inter-500-text', 'component-name')}
                {renderItem(component.description, 'COMPONENT-DESCRIPTION', false, 'px-2')}
                {renderItem(component.qty, 'COMPONENT-QTY', false, 'text-center w-full px-2')}
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default QuoteDetailsProductItem;

const CustomMenuWrapper = styled.div`
  * {
    scrollbar-width: auto;
  }
  *::-webkit-scrollbar {
    width: 5px;
    height: auto;

    &-track {
      background: transparent;
    }

    &-thumb {
      background: ${({ theme }) => theme.primary_500} !important;
      -webkit-border-radius: 5px;
      border-radius: 5px;
    }
  }
`;
