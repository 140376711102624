import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CrossIcon } from '../../../assets/icons/CrossIcons.svg';
import { ReactComponent as ProjectIcon } from '../../../assets/icons/folder-outline.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/up-arrow.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/user-outline.svg';
import { ReactComponent as JobIcon } from '../../../assets/images/jobs-outline.svg';
import { capitalize, formatText } from '../../../helpers/utils';
import IconContainer from '../../common/icon-container';

const AppliedFilters = ({
  boardsFilters,
  setBoardsFilters = () => {},
  setOrderBy = () => {},
  setSortBy = () => {},
  sortBy,
  orderBy,
  showClearAll = true,
  canRemoveFilters = true,
  containerClassname = 'px-6 pt-6 pb-2',
}) => {
  const { t } = useTranslation();
  const { project, job_type, campaign, owners: filterOwners } = boardsFilters || {};

  const handleRemoveFilters = key => {
    const { [key]: _, ...updatedFilters } = boardsFilters;
    setBoardsFilters(updatedFilters);
  };

  const handleRemoveOwner = id => {
    const newPeople = (filterOwners || []).filter(owner => owner.id !== id);
    setBoardsFilters({
      ...boardsFilters,
      owners: newPeople,
    });
  };

  const RenderContent = ({ icon, name, onRemoveClick, value }) => {
    return (
      <div className="inter-400-text natural-400-text font-12 one-line flex items-center gap-1 px-2 py-1 border radius-full">
        <IconContainer
          Icon={icon}
          iconHeight={12}
          iconWidth={12}
          iconColor={'natural_700'}
          backgroundColor="transparent"
          iconContainerClassname="p-0"
        />
        <div>
          {name}: <span className="inter-500-text natural-700-text font-12">{value}</span>
        </div>
        {canRemoveFilters && (
          <IconContainer
            Icon={CrossIcon}
            iconHeight={12}
            iconWidth={12}
            iconColor={'natural_700'}
            backgroundColor="transparent"
            iconContainerClassname="p-0 cursor"
            onClick={onRemoveClick}
          />
        )}
      </div>
    );
  };

  return (
    <div className={classNames(containerClassname)}>
      <div className="flex items-center gap-4 overflow-x-auto w-full overflow-scroll custom-horizontal">
        <div className="flex items-center gap-2">
          <label className="inter-500-text natural-700-text font-12 one-line">Sorted by:</label>
          <div className="inter-400-text natural-700-text font-12 one-line flex items-center gap-1 px-2 py-1 border radius-full">
            <IconContainer
              Icon={ArrowDownIcon}
              iconHeight={12}
              iconWidth={12}
              iconColor={'natural_700'}
              backgroundColor="transparent"
              iconContainerClassname={classNames('p-0', { 'rotate-180': orderBy === 'DESC' })}
            />
            <div className="inter-400-text natural-400-text font-12 col-gap-1 flex">
              {capitalize(formatText(sortBy, ' '))} :
              <span className="inter-500-text natural-700-text font-12">
                {orderBy === 'DESC' ? 'Oldest first' : 'Newest first'}
              </span>
            </div>
            {canRemoveFilters && (
              <IconContainer
                Icon={CrossIcon}
                iconHeight={12}
                iconWidth={12}
                iconColor={'natural_700'}
                backgroundColor="transparent"
                iconContainerClassname={classNames('cursor p-0', {
                  'rotate-180': orderBy === 'DESC',
                })}
                onClick={() => {
                  setSortBy('created_date');
                  setOrderBy('DESC');
                }}
              />
            )}
          </div>
        </div>
        {(project || job_type || campaign || filterOwners) && (
          <div className="flex items-center gap-2">
            <label className="inter-500-text natural-700-text font-12 one-line">Filterd by:</label>
            <div className="flex items-center gap-3">
              {project && (
                <RenderContent
                  icon={ProjectIcon}
                  name={'Project is'}
                  onRemoveClick={() => handleRemoveFilters('project')}
                  value={capitalize(formatText(project?.name, ' '))}
                />
              )}
              {job_type && (
                <RenderContent
                  icon={JobIcon}
                  name={'Job type is'}
                  onRemoveClick={() => handleRemoveFilters('job_type')}
                  value={capitalize(formatText(job_type?.name, ' '))}
                />
              )}
              {campaign && (
                <RenderContent
                  icon={JobIcon}
                  name={'Campaign is'}
                  onRemoveClick={() => handleRemoveFilters('campaign')}
                  value={capitalize(formatText(campaign?.name, ' '))}
                />
              )}
              {filterOwners &&
                filterOwners.map(item => (
                  <RenderContent
                    key={item?.id}
                    icon={UserIcon}
                    name={'Owner is'}
                    onRemoveClick={() => handleRemoveOwner(item?.id)}
                    value={capitalize(formatText(item?.name, ' '))}
                  />
                ))}
            </div>
          </div>
        )}
        {showClearAll && (
          <div
            className="cursor inter-500-text primary-500-text underline font-12"
            onClick={() => {
              setBoardsFilters({});
              setSortBy('created_date');
              setOrderBy('DESC');
            }}>
            <div className="w-48px">{t('CLEAR_ALL')}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppliedFilters;
