import classNames from 'classnames';
import { motion } from 'framer-motion';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import { ReactComponent as Loading } from '../../assets/images/loading.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { createNumberMask } from '../../helpers/createNumberMask';
import { getCurrencySymbol } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { getStageDecisions, getWorkitemDetails, getWorkitemProgress } from '../../store/features/boardSlice';
import { updateJob } from '../../store/features/jobSlice';
import { getJobDetails } from '../../store/features/propertySlice';
import { addToast } from '../../store/features/toastSlice';
import {
  setAllowedProgressAbleStageIds,
  setStageDecisions,
  setWorkItemProgress,
} from '../../store/features/workitemSlice';
import Button from '../common/button/button';
import DateSelectorElement from '../common/date-selector/date-selector-element';
import InputElement from '../common/input';
import CustomTooltip from '../common/tooltip-new';
import WorkitemProgress from './workitem-progress';

const WorkitemHeader = ({ loading = false, setSkipBlocker }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showErrorToast } = useError();

  const { setModal } = useContext(OrganisationContext);
  const { board_id, property_id } = useParams();
  const { workitemDetails } = useSelector(state => state.board);
  const { owners, stage, id: workitem_id } = workitemDetails || {};

  const { jobDetails } = useSelector(state => state.property);
  const { engagement, job_number } = jobDetails;
  const { name, project, engagement_value, engagement_dates, primary_contact, campaign } = engagement || {};

  const { stageDecisions, workItemProgress, allowedProgressAbleStageIds } = useSelector(state => state.workItem);

  const [isLoading, setIsLoading] = useState(false);
  const [isValueEdit, setIsValueEdit] = useState(false);
  const [isNameEdit, setIsNameEdit] = useState(false);
  const [error, setError] = useState({});
  const [workItemValue, setWorkItemValue] = useState({});
  const [active_key, setActive_key] = useState('');

  const currentStageIndex = [...workItemProgress]?.findLastIndex(({ arrived_at }) => arrived_at);

  const fetchWorkitemDetails = workitem_id => {
    dispatch(getWorkitemDetails({ workitem_id: workitem_id }));
  };

  const fetchWorkitemProgressDetails = workitem_id => {
    dispatch(
      getWorkitemProgress({
        workitem_id: workitem_id,
        params: {
          board_id: board_id,
        },
      }),
    )
      .then(data => dispatch(setWorkItemProgress(data)))
      .catch(() => dispatch(setWorkItemProgress([])));
  };

  const fetchStageDecisions = stage_id => {
    dispatch(getStageDecisions({ stage_id }))
      .then(data => dispatch(setStageDecisions(data)))
      .catch(error => dispatch(setStageDecisions([])));
  };

  const fetchJobDetails = async job_id => {
    await dispatch(getJobDetails({ job_id: job_id })).catch(() => {});
  };

  useEffect(() => {
    if (workitem_id) {
      fetchWorkitemProgressDetails(workitem_id);
    }
  }, [workitem_id, stage?.id]);

  useEffect(() => {
    if (stage?.id) {
      fetchStageDecisions(stage?.id);
    }
  }, [stage]);

  useEffect(() => {
    const stageDecisionIds = stageDecisions?.map(({ stage }) => stage.id);
    const transitionStageIds = workItemProgress
      ?.map(({ stage }) => stage.stage_type === 'TRANSITION' && stage.id)
      .filter(Boolean);
    dispatch(setAllowedProgressAbleStageIds([...stageDecisionIds, ...transitionStageIds]));
  }, [stageDecisions, workItemProgress]);

  const onJobMoved = release => {
    if (release) {
      const redirectRoute = property_id
        ? `/properties/property/${property_id}?selectedTab=JOBS`
        : `/boards/board/${board_id}`;
      navigate(redirectRoute);
    }
    fetchWorkitemDetails(workitem_id);
    fetchWorkitemProgressDetails(workitem_id);
    fetchStageDecisions(stage?.id);
  };

  const openMoveToStagePopup = stage => {
    if (!allowedProgressAbleStageIds.includes(stage.id)) {
      dispatch(
        addToast({
          error: true,
          text: t('CANNOT_MOVE_JOBS'),
          id: stage.id,
        }),
      );
      return;
    }
    const decisionStage = stageDecisions?.find(({ stage: { id } }) => id === stage.id);
    setModal({
      type: 'move-to-stage',
      content: {
        workitem_id: workitemDetails?.id,
        stage: stage,
        decisionStage: decisionStage,
        setSkipBlocker,
        onSuccess: release => onJobMoved(release),
      },
    });
  };

  const onUpdate = async label => {
    setIsLoading(true);
    let formattedJobValue = '';
    let jobName = name;
    if (isValueEdit) {
      formattedJobValue = workItemValue?.budget?.replace('£', '') || null;
    }
    if (isNameEdit) {
      jobName = workItemValue?.name;
    }
    try {
      const request = {
        job_number: job_number,
        engagement: {
          name: jobName,
          project: {
            id: project?.id,
            name: project?.name,
          },
          campaign: null,
          engagement_dates: {
            expected_completion: workItemValue?.expected_completion || engagement_dates?.expected_completion,
            deadline: workItemValue?.deadline || engagement_dates?.deadline,
          },
          engagement_value: {
            budget: formattedJobValue || engagement_value?.budget,
            contracted_amount: engagement_value?.contracted_amount,
            currency: engagement_value?.currency,
          },
          contact: {
            id: primary_contact?.id,
          },
        },
      };
      const updateValue = await dispatch(updateJob({ id: engagement?.id, request }));
      dispatch(addToast({ error: false, text: `Job${label} ${t('UPDATED_SUCCESSFULLY')}` }));
      if (updateValue && engagement?.id) {
        fetchJobDetails(engagement?.id);
      }
      setIsLoading(false);
      setIsValueEdit(false);
      setIsNameEdit(false);
    } catch (error) {
      setIsLoading(false);
      showErrorToast({ error, default_message: t('ERROR_WHILE_UPDATE_JOB') });
    }
  };

  const onJobUpdate = (key, value) => {
    let additionalUpdate = {};
    setWorkItemValue({ ...workItemValue, ...additionalUpdate, [key]: value });
    setError({ ...error, [key]: error?.[key] && !value });
  };

  useEffect(() => {
    if (workItemValue?.expected_completion) {
      let label = t('EXPECTED_COMPLETION');
      onUpdate(label);
    }
  }, [workItemValue?.expected_completion]);

  useEffect(() => {
    if (workItemValue?.deadline) {
      let label = t('DEADLINE');
      onUpdate(label);
    }
  }, [workItemValue?.deadline]);

  useEffect(() => {
    if (engagement) {
      setWorkItemValue({
        ...engagement,
        budget: engagement_value?.budget,
      });
    }
  }, [engagement]);

  const onExpectedCompletionChange = date => {
    if (engagement_dates?.deadline && date > engagement_dates?.deadline) {
      dispatch(
        addToast({
          error: true,
          text: t('START_DATE_ERROR'),
          id: nanoid(),
        }),
      );
      return;
    }
    onJobUpdate('expected_completion', date);
  };

  const onDeadlineChange = date => {
    if (engagement_dates?.expected_completion && date < engagement_dates?.expected_completion) {
      dispatch(
        addToast({
          error: true,
          text: t('END_DATE_ERROR'),
          id: nanoid(),
        }),
      );
      return;
    }
    onJobUpdate('deadline', date);
  };

  if (loading) {
    return (
      <Skeleton
        height={'130px'}
        containerClassName="line-height-1 workitem-loader"
        baseColor="#CDCDCD"
        highlightColor="#E8E8E8"
      />
    );
  }

  const onBlur = e => {
    e.preventDefault();
    setActive_key('');
  };

  const renderJobName = () => {
    return (
      <CustomTooltip
        clickable={true}
        wrapperClassName="one-line pr-2"
        tooltipClassname="w-full"
        id="job-name"
        place="bottom"
        noArrow={true}
        hidden={!isNameEdit}
        isOpen={isNameEdit}
        onBlurTooltip={() => setIsNameEdit(false)}
        content={
          <div className="flex-column work-item-velue-wrapper gap-5px">
            <div className="flex-1 mb-1">
              <span className="inter-500-text natural-900-text font-14">{t('JOB_NAME')}</span>
            </div>
            <InputElement
              placeholder={t('JOB_NAME')}
              value={workItemValue?.name || ''}
              autoFocus
              onChange={value => onJobUpdate('name', value)}
              style={{ width: '300px' }}
            />
            <div className="flex justify-between w-full gap-3 py-3">
              <Button
                className={classNames('primary-grey', { disabled: loading })}
                label={'Cancel'}
                disabled={loading}
                onClick={() => {
                  setWorkItemValue({ ...workItemValue, name: name });
                  setIsNameEdit(false);
                }}
                size="medium"
                borderRadius="100px"
              />
              <Button
                className={classNames('primary', { disabled: loading })}
                loading={isLoading}
                label={'Update'}
                disabled={loading}
                onClick={() => onUpdate(' Name ')}
                size="medium"
                borderRadius="100px"
              />
            </div>
          </div>
        }>
        <div
          className={classNames(
            'flex items-center justify-center px-1 py-0_5 radius-1 cursor background-hover',
            active_key === 'NAME' && 'background-active',
          )}
          onClick={() => {
            setIsNameEdit(!isNameEdit);
            setActive_key('NAME');
          }}>
          <label className="inter-600-text natural-900-text font-20 one-line">{name}</label>
        </div>
      </CustomTooltip>
    );
  };

  return (
    <WorkitemHeaderWrapper
      key={'workitem-header'}
      initial={{ opacity: 0.3, y: 10 }}
      onBlur={onBlur}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      className="card radius-2 pxy-6 flex-column">
      <div className="flex items-center justify-between">
        {renderJobName()}
        <div className="flex items-center justify-center">
          <div className="border-right border-left px-3">
            <div className="flex items-center justify-center px-1 py-0_5">
              <label className="inter-400-text natural-400-text mr-1">{t('CAMPAIGN')}:</label>
              <label className="inter-400-text natural-900-text">{campaign ? campaign?.name : '-'}</label>
            </div>
          </div>
          <div className="border-right px-3">
            <div className="flex items-center justify-center px-1 py-0_5">
              <label className="inter-400-text natural-400-text mr-1">Project:</label>
              <label className="inter-400-text natural-900-text">{project?.name}</label>
            </div>
          </div>

          <CustomTooltip
            wrapperClassName="job-tooltip border-right px-3"
            id={`${workitem_id}-stage`}
            place="bottom"
            noArrow={true}
            isOpen={isValueEdit}
            onBlurTooltip={() => setIsValueEdit(false)}
            content={
              <div className="flex-column work-item-velue-wrapper gap-5px">
                <div className="flex-1 mb-1">
                  <span className="inter-500-text natural-900-text font-14">{t('VALUE')}</span>
                </div>
                <MaskedInput
                  mask={createNumberMask({
                    prefix: '£',
                    allowDecimal: true,
                    includeThousandsSeparator: false,
                  })}
                  value={workItemValue?.budget || ''}
                  className={`input w-full ${error?.job_value ? 'error-border' : ''}`}
                  placeholder={'£00.00'}
                  guide={false}
                  onChange={({ target: { value } }) => onJobUpdate('budget', value)}
                />
                <div className="flex justify-between w-full gap-3 py-3">
                  <Button
                    className={`primary-grey  ${loading && 'disabled'}`}
                    label={'Cancel'}
                    disabled={loading}
                    onClick={() => {
                      setWorkItemValue({ ...workItemValue, budget: engagement_value?.budget });
                      setIsValueEdit(false);
                    }}
                    size="medium"
                    borderRadius="100px"
                  />

                  <Button
                    className={`primary  ${loading && 'disabled'}`}
                    label={isLoading ? <Loading height={16} /> : 'Update'}
                    disabled={loading}
                    onClick={() => onUpdate(' Value ')}
                    size="medium"
                    borderRadius="100px"
                  />
                </div>
              </div>
            }>
            <div
              className={classNames(
                'flex items-center justify-center px-1 py-0_5 radius-1 cursor background-hover',
                active_key === 'BUDGET' && 'background-active',
              )}
              onClick={() => {
                setIsValueEdit(!isValueEdit);
                setActive_key('BUDGET');
              }}>
              <label className="inter-400-text natural-400-text mr-1">{t('VALUE')}:</label>
              <label className="inter-400-text natural-900-text">
                {engagement_value?.budget ? (
                  <>
                    {getCurrencySymbol(engagement_value?.currency || 'gbp')}
                    {engagement_value?.budget || '-'}
                  </>
                ) : (
                  '-'
                )}
              </label>
            </div>
          </CustomTooltip>
          <div className="border-right px-3">
            <div
              className={classNames(
                'flex items-center justify-center px-1 py-0_5 radius-1 background-hover',
                active_key === 'EXPECTED_COMPLETION' && 'background-active',
              )}
              onClick={() => setActive_key('EXPECTED_COMPLETION')}>
              <label className="inter-400-text natural-400-text mr-1">{t('EXP_COMPLETION')}:</label>
              <DateSelectorElement
                selectedDate={workItemValue?.expected_completion || engagement_dates?.expected_completion}
                isCalendarIcon={false}
                setSelectedDate={date => onExpectedCompletionChange(date)}
                className="workitem-date-field p-0"
                placeholder={'DD/MM/YYYY'}
              />
            </div>
          </div>
          <div className="pl-3">
            <div
              className={classNames(
                'flex items-center justify-center px-1 py-0_5 radius-1 background-hover',
                active_key === 'DEADLINE' && 'background-active',
              )}
              onClick={() => setActive_key('DEADLINE')}>
              <label className="inter-400-text natural-400-text mr-1">{t('DEADLINE')}:</label>
              <DateSelectorElement
                selectedDate={workItemValue?.deadline || engagement_dates?.deadline || ''}
                isCalendarIcon={false}
                setSelectedDate={date => onDeadlineChange(date)}
                className="workitem-date-field p-0"
                placeholder={'DD/MM/YYYY'}
              />
            </div>
          </div>
        </div>
      </div>
      <WorkitemProgress
        currentStageIndex={currentStageIndex}
        workitemProgress={workItemProgress}
        allowedProgressableStageIds={allowedProgressAbleStageIds}
        openMoveToStagePopup={openMoveToStagePopup}
      />
    </WorkitemHeaderWrapper>
  );
};

const WorkitemHeaderWrapper = styled(motion.div)`
  .arrow-steps .step {
    margin: 0 4px 0 0;
    padding: 8px 30px;
    position: relative;
    min-height: 36px;
    max-height: 36px;
    background-color: ${({ theme }) => theme.natural_200};
    user-select: none;
    transition: background-color 0.2s ease;
  }

  .step.hidden {
    display: none;
  }
  .arrow-steps .step:after,
  .arrow-steps .step:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: -16px;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 18px solid transparent;
    border-left: 17px solid ${({ theme }) => theme.natural_200};
    z-index: 2;
    transition: border-color 0.2s ease;
  }

  .arrow-steps .step:before {
    right: auto;
    left: 0;
    border-left: 18px solid #fff;
    z-index: 0;
  }

  .arrow-steps .step:first-child:before {
    border: none;
  }

  .arrow-steps .step:last-child:after {
    border: none;
  }

  .arrow-steps .step:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .arrow-steps .step:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .arrow-steps .step.current {
    background-color: ${({ theme }) => theme.selectedText};
  }

  .arrow-steps .step.current:after {
    border-left: 17px solid ${({ theme }) => theme.selectedText};
  }

  .arrow-steps .step.hover-green:hover {
    background-color: ${({ theme }) => theme.success_500};
    :after {
      border-left: 17px solid ${({ theme }) => theme.success_500};
    }
    .hover-text {
      color: #fff;
    }
  }

  .arrow-steps .step.hover-red:hover {
    background-color: ${({ theme }) => theme.error_500};
    :after {
      border-left: 17px solid ${({ theme }) => theme.error_500};
    }
    .hover-text {
      color: #fff;
    }
  }

  .step-expand label {
    position: relative;
    top: -2px;
    line-height: 34px;
  }

  .work-item-velue-wrapper {
    z-index: 2;
  }

  .workitem-date-field {
    border: none;
    min-height: auto;
    border-radius: 0%;
    box-shadow: none;
  }

  .job-tooltip {
    .react-tooltip {
      pointer-events: initial !important;
    }
  }

  .background-hover {
    &:hover {
      background-color: ${({ theme }) => theme.natural_150};
    }
  }

  .background-active {
    background-color: ${({ theme }) => theme.natural_150};
  }

  .dropdown-list-wrapper {
    .react-tooltip {
      top: 210px !important;
    }
  }

  .step-right,
  .step-left {
    width: 70px;
  }
`;

export default WorkitemHeader;
