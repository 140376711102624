import { t } from 'i18next';
import React, { Fragment, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import AppliedFilters from '../../components/board-details/filter/applied-filters';
import PageHeader from '../../components/page-header';
import TaskList from '../../components/task-list/task-list';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { useError } from '../../hooks/useError';
import { getTaskList } from '../../store/features/taskSlice';

const Tasks = () => {
  const dispatch = useDispatch();
  const { showErrorToast } = useError();

  const { setSideModal } = useContext(OrganisationContext);

  const { user } = useSelector(state => state.user);
  const assignee_ids = [user?.user?.id];

  const [sortBy, setSortBy] = useState('due_date,priority');
  const [orderBy, setOrderBy] = useState('desc');
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  const fetchTasksList = (page, merge, showLoading = true) => {
    return dispatch(
      getTaskList({
        merge,
        params: {
          page: page,
          size: 20,
          search: debouncedSearch ?? undefined,
          status: 'BLOCKED,TODO,STARTED',
          assignee_id: assignee_ids,
          sort_by: sortBy,
          order_by: orderBy,
        },
      }),
    ).catch(error => {
      showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_TASKS') });
    });
  };

  const handleShowAddTask = () => {
    setSideModal({
      type: 'add-new-task',
      content: {
        fromTaskList: true,
        onSuccess: () => {
          fetchTasksList(0, false);
        },
      },
    });
  };

  return (
    <TasksWrapper className="flex-column flex-1">
      <Routes>
        <Route
          index
          element={
            <Fragment>
              <PageHeader
                headerTitle={t('TASKS')}
                showAddNewBtn
                showSearch
                showSort
                wrapperClassName="px-6 pb-4 pt-5"
                addButtonLabel={t('ADD_TASKS')}
                addBtnPermission="TASKS_MANAGE"
                searchText={searchText}
                onSearchChange={setSearchText}
                onAddClick={handleShowAddTask}
                // SortWrapper={SortWrapper}
              />
              <AppliedFilters sortBy={sortBy} orderBy={orderBy} showClearAll={false} canRemoveFilters={false} />
              <TaskList debouncedSearch={debouncedSearch} fetchTasksList={fetchTasksList} />
            </Fragment>
          }
        />
      </Routes>
    </TasksWrapper>
  );
};

const TasksWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;

export default Tasks;
