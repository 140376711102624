import { motion } from 'framer-motion';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { useError } from '../../hooks/useError';
import { getPropertyTaskList } from '../../store/features/taskSlice';
import AppliedFilters from '../board-details/filter/applied-filters';
import Button from '../common/button/button';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import InputSearch from '../common/input-search';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import TaskListItem from '../task-list/task-list-item';

const taskTableHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'SUBTASKS', key: 'SUBTASKS' },
  { name: 'DATE', key: 'DATE' },
  // { name: 'TYPE', key: 'TYPE' },
  // { name: 'STATUS', key: 'STATUS' },
  { name: 'PRIORITY', key: 'PRIORITY' },
  { name: 'ASSIGNEE', key: 'ASSIGNEE' },
  { name: '', key: 'MENU' },
];

const PropertyTask = () => {
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const [propertyTasks, setPropertyTasks] = useState({ content: [] });
  const { content: tasks, last } = propertyTasks || {};
  const { setSideModal } = useContext(OrganisationContext);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const pageRef = useRef(null);
  const dispatch = useDispatch();
  const { workitemDetails } = useSelector(state => state.board);
  const engagement_id = workitemDetails?.engagement?.id;

  const [sortBy, setSortBy] = useState('due_date,priority');
  const [orderBy, setOrderBy] = useState('desc');

  const fetchTaskList = (page, merge, debouncedSearch, showLoading = true) => {
    pageRef.current = page;
    if (showLoading && !merge) {
      setLoading(true);
    }
    dispatch(
      getPropertyTaskList({
        params: {
          engagement_id: engagement_id,
          page: page,
          size: 10,
          search: debouncedSearch,
          sort_by: sortBy,
          order_by: orderBy,
        },
      }),
    )
      .then(data => {
        if (merge) {
          setPropertyTasks({ ...data, content: [...propertyTasks.content, ...data.content] });
        } else {
          setPropertyTasks(data);
        }
        pageRef.current = page;
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_TASKS') }))
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    fetchTaskList(pageRef.current + 1, true, debouncedSearch, false);
  };

  useEffect(() => {
    fetchTaskList(0, false, debouncedSearch, true);
  }, [debouncedSearch]);

  const refetchData = () => {
    fetchTaskList(0, false, debouncedSearch, false);
  };

  const handleShowAddTask = () => {
    setSideModal({
      type: 'add-new-task',
      taskType: 'job-task',
      content: {
        fromJobTasks: true,
        onSuccess: () => {
          setPropertyTasks({ content: [] });
          refetchData();
        },
      },
    });
  };

  return (
    <motion.div
      key={'property-task'}
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      style={{ height: '100vh', overflow: 'auto' }}
      className="pxy-6 flex-column flex-1">
      <div className="flex justify-between">
        <div className="flex align-center">
          <InputSearch placeholder={t('SEARCH')} onChange={setSearch} />
        </div>
        <Button
          fontSize="14px"
          size="average"
          width="116px"
          height="40px"
          borderRadius="100px"
          lableSize="w-64 line-height-20"
          className="primary specified-width  px-4 py-2_5 ml-3"
          afterIcon={<AddIcon className="mr-0 white-text" height={16} width={16} />}
          label={t('ADD_NEW')}
          onClick={handleShowAddTask}
        />
      </div>
      <AppliedFilters
        sortBy={sortBy}
        orderBy={orderBy}
        showClearAll={false}
        canRemoveFilters={false}
        containerClassname="pt-4"
      />
      <TaskListWrapper className="flex-column flex-1  radius-1 mt-6 overflow-hidden">
        <SkeletonTransition
          loading={loading}
          loaderClassName="item flex items-center justify-center flex-1 mb-6"
          height={'100%'}
          containerClassName="line-height-1 h-full w-full">
          {(tasks || []).length > 0 ? (
            <div className="w-full card radius-1_5 border overflow-scroll">
              <div className="flex-column w-fit-content min-w-full h-full">
                <TableHeader headers={taskTableHeaders} />
                <InfiniteScrollV2
                  hasMore={!last}
                  fetchMoreData={fetchMoreData}
                  infiniteScrollClassName="custom-scrollbar thin-scrollbar">
                  {(tasks || []).map(task => (
                    <TaskListItem
                      task={task}
                      key={task.id}
                      arrows={true}
                      isPropertyTaskList={true}
                      fetchTasks={refetchData}
                    />
                  ))}
                </InfiniteScrollV2>
              </div>
            </div>
          ) : debouncedSearch ? (
            <div className="flex-column flex-1 mb-6 items-center justify-center">
              <NoData
                title={t('NO_RESULTS_FOUND')}
                description={t('NO_RESULTS_DESCRIPTION')}
                className="search-terms"
                EmptyIcon={NoDocumentIcon}
                iconClassName="mb-6 relative"
              />
            </div>
          ) : (
            <div className="flex-column flex-1 mb-6 items-center justify-center">
              <NoData
                title={t('NO_TASKS')}
                className="search-terms"
                description={t('NO_TASKS_HERE')}
                EmptyIcon={NoDocumentIcon}
                iconClassName="mb-6 relative"
              />
            </div>
          )}
        </SkeletonTransition>
      </TaskListWrapper>
    </motion.div>
  );
};

const TaskListWrapper = styled.div`
  border-radius: 6px;
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns:
      minmax(200px, 1fr) minmax(80px, 100px) minmax(80px, 100px)
      minmax(120px, 150px) minmax(100px, 120px) 32px;
    column-gap: 48px;
    padding: 0 31px;
  }

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .no-result-container,
  .no-data-container {
    width: 300px;
  }

  .search-terms {
    max-width: 343px;
  }
`;

export default PropertyTask;
