import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { components } from 'react-select';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as CheckDoneIcon } from '../../assets/icons/check-done-black.svg';
import { ReactComponent as PinIcon } from '../../assets/icons/map-pin.svg';
import { ReactComponent as Chart } from '../../assets/icons/property/chart.svg';
import { ReactComponent as Square } from '../../assets/icons/property/square.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { getDateDifference } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { setWorkitemDetails } from '../../store/features/boardSlice';
import { getJobTypeList, updateJob } from '../../store/features/jobSlice';
import { getJobDetails } from '../../store/features/propertySlice';
import { addToast } from '../../store/features/toastSlice';
import { getWorkItemAccess, updateWorkitemOwners } from '../../store/features/workitemSlice';
import { Avatar } from '../common/avatar-group';
import IconContainer from '../common/icon-container';
import SearchableDropdown from '../common/searchable-dropdown';
import SkeletonTransition from '../common/skeleton-transition';

const CustomOwnerOption = ({ data, innerProps, innerRef, selectProps, isSelected }) => {
  return (
    <div className="flex items-center px-4 py-3 border-bottom cursor" ref={innerRef} {...innerProps}>
      <Avatar variant="medium" avatar={data || data?.user} backgroundColorIndex={0} />
      <label className="inter-400-text natural-900-text font-14 ml-2 flex-1">{data?.name || data?.user?.name}</label>
      {isSelected && (
        <IconContainer
          Icon={CheckDoneIcon}
          backgroundColor="transparent"
          iconColor="primary_500"
          iconContainerClassname="p-0"
        />
      )}
    </div>
  );
};

const CustomValueContainer = ({ children, ...props }) => {
  const {
    isMulti,
    hasValue,
    selectProps: { value },
  } = props;

  const firstValue = value?.[0] || {};
  const remainingValues = value?.length - 1;

  return (
    <components.ValueContainer {...props}>
      {value?.length > 0 && (
        <div className="flex items-center">
          <Avatar variant="medium" avatar={firstValue?.user || firstValue} backgroundColorIndex={0} />
          <label className="inter-400-text natural-900-text font-14 ml-2 wrap flex-1 cursor">
            {firstValue?.user?.name || firstValue?.name}
          </label>
          {remainingValues > 0 && (
            <div className="flex items-center px-2 py-1 ml-2 bg-white radius-6 nowrap cursor">
              <label className="inter-400-text natural-700-text font-14">{`+ ${remainingValues}`}</label>
            </div>
          )}
        </div>
      )}
      <div className="flex">{children}</div>
    </components.ValueContainer>
  );
};

const PropertyJobOwners = ({ fromProperty = false }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { showErrorToast } = useError();

  const { t } = useTranslation();
  const { board_id } = useParams();

  const { userDetails, userOrganization } = useSelector(state => state.user);
  const { workitemDetails } = useSelector(state => state.board);

  const { owners, id: workitem_id } = workitemDetails || {};

  const [loading, setLoading] = useState(false);
  const [searchedOwner, setSearchedOwner] = useState('');
  const [selectedOwner, setSelectedOwner] = useState(owners);

  const showAssignToMe = !fromProperty && !owners?.some(owner => owner?.user?.id === userDetails?.id);

  useEffect(() => {
    setSelectedOwner(owners);
  }, [owners]);

  const onUpdateOwners = ownersToUpdate => {
    const isSameOwners = JSON.stringify(ownersToUpdate) === JSON.stringify(owners);
    if (loading || isSameOwners) return;
    setLoading(true);
    const request = (ownersToUpdate || []).map(owner => ({
      user: {
        id: owner?.id,
      },
      organization: {
        id: userOrganization?.id,
      },
    }));
    dispatch(updateWorkitemOwners({ workitem_id: workitem_id, request }))
      .then(data => {
        dispatch(addToast({ error: false, text: t('JOB_OWNERS_UPDATED_SUCCESSFULLY'), id: nanoid() }));
        dispatch(setWorkitemDetails({ ...workitemDetails, owners: data }));
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_UPDATING_JOB_OWNERS') });
      })
      .finally(() => setLoading(false));
  };

  const onAssignToMe = () => {
    const request = [{ id: userDetails?.id }];
    onUpdateOwners(request);
  };

  const renderOwners = () => {
    const firstOwner = selectedOwner?.[0] || {};
    const remainingOwners = selectedOwner?.length - 1;

    return (
      <div className="flex items-center">
        {selectedOwner.length > 0 ? (
          <Fragment>
            <Avatar variant="medium" avatar={firstOwner?.user || firstOwner} backgroundColorIndex={0} />
            <label className="inter-400-text natural-900-text font-14 ml-2 one-line flex-1">
              {firstOwner?.user?.name || firstOwner?.name}
            </label>
            {remainingOwners > 0 && (
              <div className="flex items-center px-2 ml-2 bg-white radius-6 nowrap">
                <label className="inter-400-text natural-700-text font-14">{`+ ${remainingOwners}`}</label>
              </div>
            )}
          </Fragment>
        ) : (
          <label className="inter-400-text natural-400-text font-14">{t('NO_OWNERS')}</label>
        )}
      </div>
    );
  };

  return (
    <div className="flex items-start justify-between">
      <label className="flex-1 inter-400-text natural-400-text line-height-20 font-14 my-1">{t('OWNER')}</label>
      <div className="flex-column items-center justify-end">
        {fromProperty ? (
          renderOwners()
        ) : (
          <SearchableDropdown
            inputValue={searchedOwner}
            onInputChange={setSearchedOwner}
            placeholder={t('NO_OWNERS')}
            isCustomSearchable={true}
            isMulti={false}
            openMenuOnFocus
            value={selectedOwner?.length ? selectedOwner : null}
            onChange={options => {
              onUpdateOwners([options]);
              setSelectedOwner([options]);
            }}
            getOptionValue={option => option?.user?.id || option?.id}
            defaultAdditional={{
              page: 0,
              fetchFunction: getWorkItemAccess,
              pageable: false,
              payload: { id: workitem_id },
            }}
            customStyle={{
              menu: { width: 260, right: 0 },
              control: {
                minHeight: 28,
                border: 'none',
                cursor: 'pointer',

                '&:hover': {
                  backgroundColor: theme.natural_100,
                  borderColor: 'none',
                  boxShadow: 'none',
                },
              },
              valueContainer: { minHeight: 24, padding: '4px' },
              indicatorContainer: {
                display: 'none',
              },
              dropdownIndicator: {
                display: 'none',
              },
              loadingIndicator: {
                display: 'none',
              },
            }}
            customComponent={{
              Option: CustomOwnerOption,
              ValueContainer: CustomValueContainer,
              SingleValue: () => null,
            }}
          />
        )}
        {showAssignToMe && (
          <div className="flex items-center justify-end w-full">
            <span
              className="inter-500-text font-12 blue-primary-icon cursor text-right w-auto text-hover"
              onClick={onAssignToMe}>
              {t('ASSIGN_TO_ME')}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const PropertyJobDetails = ({ loading = false, fromProperty = false, showViewDetails = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { showErrorToast } = useError();

  const { workitemDetails } = useSelector(state => state.board);
  const { stage, workflow } = workitemDetails;

  const { propertyDetails, jobDetails } = useSelector(state => state.property);
  const { job_description, job_number, job_type, po_number, engagement, lead_gen } = jobDetails || {};
  const { formatted_address, id: property_Id, name: property_name, property_class } = propertyDetails || {};
  const { name, project, engagement_value, engagement_dates, primary_contact, campaign, agent } = engagement || {};
  const { class_type } = property_class || {};

  const isCommercial = class_type === 'COMMERCIAL';
  const isLand = class_type === 'LAND';

  const [isShowMore, setIsShowMore] = useState(false);
  const [isEditKey, setEditKey] = useState('');
  const [jobEditValue, setJobEditValue] = useState('');
  const [searchJob, setSearchJob] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const clickToViewDetails = () => {
    navigate(`/properties/property/${property_Id}`);
  };

  const textareaRef = useRef(null);

  const updateTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  };

  useLayoutEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      updateTextareaHeight();
    }
  }, [textareaRef.current, isEditKey, jobEditValue.job_description]);

  useEffect(() => {
    setJobEditValue(jobDetails);
  }, [jobDetails]);

  const fetchJobDetails = async job_id => {
    await dispatch(getJobDetails({ job_id: job_id })).catch(() => {});
  };

  const onJobUpdate = (key, value) => {
    setJobEditValue({ ...jobEditValue, [key]: value });
  };

  const onUpdate = async label => {
    if (
      jobEditValue?.job_number === job_number &&
      jobEditValue?.po_number === po_number &&
      jobEditValue?.job_description === job_description
    ) {
      return setEditKey(null);
    } else {
      setIsLoading(true);
      try {
        const request = {
          engagement: {
            name: name,
            project: project?.id
              ? {
                  id: project?.id,
                }
              : null,
            campaign: campaign,
            engagement_dates: {
              expected_completion: engagement_dates?.expected_completion,
              deadline: engagement_dates?.deadline,
            },
            engagement_value: {
              budget: engagement_value?.budget,
              contracted_amount: engagement_value?.contracted_amount,
              currency: engagement_value?.currency,
            },
            contact: {
              id: primary_contact?.id,
            },
          },
          agent: agent,
          job_number: jobEditValue?.job_number || '',
          po_number: jobEditValue?.po_number || '',
          job_description: jobEditValue?.job_description || '',
          lead_gen: lead_gen,
        };

        const updateValue = await dispatch(updateJob({ id: engagement?.id, request }));
        dispatch(addToast({ error: false, text: `${label} updated successfully` }));
        setEditKey(null);

        if (updateValue && engagement?.id) {
          fetchJobDetails(engagement?.id);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showErrorToast({ error, default_message: t('ERROR_WHILE_UPDATE_JOB') });
      }
    }
  };

  const renderInputItem = (value, key, labelValue, isEditable) => {
    return (
      <div
        className={classNames(
          'flex items-center justify-between w-full',
          key === 'JOB_DESCRIPTION' && value && 'flex-column items-start row-gap-2',
        )}>
        <label className={classNames('flex-1 inter-400-text natural-400-text line-height-20 font-14')}>
          {labelValue}
        </label>

        {key === 'JOB_DESCRIPTION' ? (
          <div
            className={classNames(
              'flex justify-start  job-description relative cursor w-fit-content',
              isEditable && 'hover-edit ',
              value && 'p-0',
            )}>
            <p
              onClick={() => setEditKey(key)}
              className={classNames(
                'inter-400-text natural-400-text line-height-20 font-14 relative custom-scrollbar thin-scrollbar',
                !isShowMore && 'three-lines',
                value && 'natural-900-text w-full',
              )}>
              {isShowMore ? job_description : job_description?.slice(0, 100) || 'No data'}
              {job_description?.length >= 100 && !isShowMore && '...'}
              {job_description?.length >= 100 && (
                <span
                  onClick={e => {
                    setIsShowMore(!isShowMore);
                    e.stopPropagation();
                  }}
                  className={classNames(
                    'inter-500-text primary-text cursor float-right font-12 description-show-more-less',
                  )}>
                  {isShowMore ? t('SHOW_LESS') : t('SHOW_MORE')}
                </span>
              )}
            </p>
          </div>
        ) : (
          <div
            className={classNames('flex ites-center radius-1_5 cursor px-1 py-0_5', isEditable && 'hover-edit')}
            onClick={() => setEditKey(key)}>
            <label
              onFocus={() => setEditKey(key)}
              tabIndex="0"
              className={classNames(
                'inter-400-text flex items-center one-line',
                value ? 'natural-900-text' : 'natural-400-text',
              )}>
              {value || 'No data'}
            </label>
          </div>
        )}
      </div>
    );
  };

  const editInputContent = (key, value, label, type) => {
    return (
      <div className="flex-column w-full input-container relative">
        <div
          className={classNames(
            'flex items-center justify-between w-full',
            key === 'job_description' && 'flex-column row-gap-2 scroll-container',
          )}>
          <label className="w-full inter-400-text natural-400-text line-height-20 font-14">{label}</label>
          {key === 'job_type' ? (
            <SearchableDropdown
              key={project?.id}
              isClearable
              inputValue={searchJob}
              onInputChange={setSearchJob}
              className="w-full"
              placeholder={t('SELECT_JOB_TYPE')}
              value={jobEditValue?.job_type}
              onChange={option => onJobUpdate('job_type', option)}
              isCustomSearchable={true}
              isDisabled={project ? false : true}
              defaultAdditional={{
                page: 0,
                fetchFunction: getJobTypeList,
                pageable: false,
                params: { project_id: project?.id },
              }}
            />
          ) : key === 'job_description' ? (
            <textarea
              defaultValue={value}
              className="inter-400-text natural-900-text overflow-auto input w-full textarea px-2 py-1 h-full job-input border"
              // ref={textareaRef}
              style={{ maxHeight: '260px' }}
              autoFocus
              onChange={({ target: { value } }) => setJobEditValue({ ...jobEditValue, [key]: value })}
            />
          ) : (
            <input
              className="inter-400-text natural-900-text one-line input w-full px-2 py-1 h-auto job-input border"
              defaultValue={value}
              type={type}
              autoFocus
              onChange={({ target: { value } }) => setJobEditValue({ ...jobEditValue, [key]: value })}
            />
          )}
        </div>
        <div className="w-full flex justify-end">
          <div className="flex justify-center items-end radius-3 job-update-action mt-1 border pxy-2 gap-2_5 absolute">
            <span
              className="flex justify-center items-center cross-icon border radius-100 cursor"
              onClick={() => {
                setJobEditValue({ ...jobEditValue });
                setEditKey(null);
              }}>
              <CrossIcon color="#171717" />
            </span>
            <span
              className="flex justify-center items-center right-icon border radius-100 cursor"
              onClick={() => onUpdate(label)}>
              <RightIcon color="#fff" />
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <SkeletonTransition
      loading={loading}
      height={'260px'}
      containerClassName="line-height-1"
      baseColor="#CDCDCD"
      highlightColor="#E8E8E8">
      <PropertyJobDetailsWrapper className="card radius-2 pxy-6 flex-column gap-5">
        <div className="justify-between items-center justify-betweenborder-bottom border-bottom pb-3">
          <label className="inter-600-text natural-900-text font-16">{t('JOB_DETAILS')}</label>
        </div>

        <div className="flex-column row-gap-3 border-bottom pb-5">
          <PropertyJobOwners loading={loading} fromProperty={fromProperty} />

          <div className="flex items-center inter-400-text natural-900-text line-height-20 font-14">
            {isEditKey === 'JOB_NUMBER'
              ? editInputContent('job_number', job_number, t('JOB_NUMBER'), 'text')
              : renderInputItem(job_number, 'JOB_NUMBER', t('JOB_NUMBER'), true)}
          </div>
          <div className="flex items-center inter-400-text natural-900-text line-height-20 font-14">
            {isEditKey === 'PO_NUMBER'
              ? editInputContent('po_number', po_number, t('PO_NUMBER'), 'text')
              : renderInputItem(po_number, 'PO_NUMBER', t('PO_NUMBER'), true)}
          </div>

          <div className="flex items-center inter-400-text natural-900-text line-height-20 font-14">
            {renderInputItem(job_type?.name || '-', 'JOB_TYPE', t('JOB_TYPE'), false)}
          </div>
          <div className="flex items-center inter-400-text natural-900-text line-height-20 font-14">
            {isEditKey === 'JOB_DESCRIPTION'
              ? editInputContent('job_description', job_description, t('JOB_DESCRIPTION'), 'text')
              : renderInputItem(job_description, 'JOB_DESCRIPTION', t('JOB_DESCRIPTION'), true)}
          </div>

          <div className="flex items-center justify-between">
            <label className="flex-1 inter-400-text natural-400-text line-height-20 font-14">
              {t('DAYS_IN_PROCESS')}
            </label>
            <label className="flex items-center inter-400-text natural-900-text line-height-20 font-14">
              <Chart className="orange-500-text mr-1" />
              {getDateDifference(workflow?.arrived, null, 'days')}d
            </label>
          </div>
          <div className="flex items-center justify-between">
            <label className="flex-1 inter-400-text natural-400-text line-height-20 font-14">
              {t('DAYS_IN_STAGE')}
            </label>
            <label className="flex items-center inter-400-text natural-900-text line-height-20 font-14">
              <Square className="success-500-text" />
              {getDateDifference(stage?.arrived, null, 'days')}d
            </label>
          </div>
        </div>
        <div className="property-details-wrapper flex-column gap-4">
          <div className="flex items-center justify-between ">
            <label className="inter-600-text natural-900-text font-14">{t('PROPERTY')}</label>
            {showViewDetails && (
              <label onClick={clickToViewDetails} className="inter-500-text primary-text cursor font-12 text-hover">
                {t('VIEW_DETAILS')}
              </label>
            )}
          </div>
          <div className="flex-column row-gap-3">
            {(isCommercial || isLand) && property_name && (
              <div className="flex-column row-gap-1">
                <label className="flex-1 inter-400-text natural-400-text">{t('PROPERTY_NAME')}</label>
                <label className="flex-1 inter-400-text natural-900-text">{property_name}</label>
              </div>
            )}
            <div className="flex">
              <IconContainer
                iconContainerClassname="mr-2 flex h-fit-content"
                iconClassName="natural-600-text"
                iconHeight={16}
                iconWidth={16}
                Icon={PinIcon}
                backgroundColor="natural_100"
              />
              <label className="flex-1 inter-400-text natural-900-text line-height-20">{formatted_address}</label>
            </div>
          </div>
        </div>
      </PropertyJobDetailsWrapper>
    </SkeletonTransition>
  );
};

export const PropertyJobDetailsWrapper = styled.div`
  .searchable-select {
    &__control--menu-is-open {
      background-color: ${({ theme }) => theme.natural_100};
    }
  }
  .cross-icon {
    width: 32px;
    height: 32px;
  }

  .right-icon {
    background-color: ${({ theme }) => theme.primary_500};
    width: 32px;
    height: 32px;
  }
  .text-hover {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      width: 0;
      height: 1px;
      bottom: -4px;
      left: 0;
      border-radius: 2px;
      transition: 400ms ease;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
    &::after {
      background: #2496ff;
    }
  }
  .icon-action {
    border-radius: 100%;
    padding: 0px;
    width: 32px;
    height: 32px;
  }
  .hover-edit {
    &:hover {
      background: ${({ theme }) => theme.natural_150};
      border-radius: 6px;
      padding: 2px 4px;
      .description-show-more-less {
        right: 8px;
        bottom: 2px;
      }
    }
  }
  .job-update-action {
    background-color: ${({ theme }) => theme.white};
    z-index: 999;
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  }
  .job-description {
    word-break: break-all;
    padding: 2px 4px;
    &:hover {
      padding: 2px 4px !important;
    }
    .description-show-more-less {
      right: 0;
      bottom: 0;
      background-color: transparent !important;
    }
    p {
      max-height: 260px;
      overflow: auto;
    }
  }
  .scroll-container textarea {
    min-height: 40px !important;
    height: 104px;
    overflow: auto;
    scrollbar-width: auto;
    ::-webkit-scrollbar {
      width: 5px;
      height: auto;

      &-track {
        margin-top: 8px;
        margin-bottom: 8px;
        border-radius: 5px;
      }

      &-thumb {
        background: ${({ theme }) => theme.primary_500} !important;
        -webkit-border-radius: 5px;
        border-radius: 5px;
      }
    }
  }
  .searchable-select__multi-value {
    display: none;
  }
  .job-input:focus {
    border: 1px solid ${({ theme }) => theme.natural_200} !important;
    box-shadow: none !important;
  }
`;

export default PropertyJobDetails;
