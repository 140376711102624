import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { components } from 'react-select';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as PlusIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as Attachment } from '../../assets/icons/attachment.svg';
import { ReactComponent as CheckDoneIcon } from '../../assets/icons/check-done-black.svg';
import { ReactComponent as CrossIcon, ReactComponent as CrossIcons } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty-document.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file-icon.svg';
import { ReactComponent as ImageAdd } from '../../assets/icons/image-add.svg';
import { ReactComponent as ImageContent } from '../../assets/icons/image-content.svg';
import { ReactComponent as LeftArrow } from '../../assets/icons/left-arrow-to-left.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/property/edit.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/right-arrow.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close-image.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/menu/arrow-down-black.svg';
import { Avatar } from '../../components/common/avatar-group';
import Button from '../../components/common/button/button';
import Checkbox from '../../components/common/checkbox';
import DateSelectorElement from '../../components/common/date-selector/date-selector-element';
import IconContainer from '../../components/common/icon-container';
import InputElement from '../../components/common/input';
import Menu from '../../components/common/menu';
import NoData from '../../components/common/no-data';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import Dropdown from '../../components/common/select-dropdown';
import SkeletonTransition from '../../components/common/skeleton-transition';
import Status from '../../components/common/status';
import CustomTooltip from '../../components/common/tooltip-new';
import { OrganisationContext } from '../../context/organisationContext';
import {
  capitalize,
  capitalizeFirstLetterOfWords,
  formatText,
  getUserInitials,
  initSideModal,
} from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { getJobs } from '../../store/features/jobSlice';
import { getEvidenceConfigs } from '../../store/features/quotesSlice';
import {
  addTaskComment,
  getTaskComments,
  getTaskDetails,
  getTaskEvidences,
  getTaskSubTasks,
  updateTask,
  updateTaskProgress,
} from '../../store/features/taskSlice';
import { addToast } from '../../store/features/toastSlice';
import { getEngagementAccess } from '../../store/features/workitemSlice';
import { TaskDetailsWrapper } from '../sidemodel.styled';
import SubTaskItem from './sub-task-item';

const commentTabs = [
  { name: 'Comments', key: 'Comments' },
  // { name: 'Activity Logs', key: 'Activity Logs' },
];

const statusOptions = [
  { label: 'To do', value: 'TODO' },
  { label: 'In-progress', value: 'STARTED' },
  { label: 'Blocked', value: 'BLOCKED' },
  { label: 'Done', value: 'COMPLETE' },
];

const priorityOptions = [
  { label: 'Highest', value: 'VERY_HIGH' },
  { label: 'High', value: 'HIGH' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'Low', value: 'LOW' },
  { label: 'Lowest', value: 'VERY_LOW' },
];

const evidenceOptions = [
  { label: 'Photo', value: 'IMAGE' },
  { label: 'Document', value: 'DOCUMENT' },
  { label: 'Video', value: 'VIDEO' },
  { label: 'Form', value: 'FORM' },
];

const TaskDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { showErrorToast } = useError();

  const { t } = useTranslation();

  const { sideModal, setSideModal } = useContext(OrganisationContext);
  const { user } = useSelector(state => state.user);

  const {
    content: { taskDetails, isJobDetailsTask, fromActivity, onSuccess, activityId, parent_task_id },
  } = sideModal;

  const [loading, setLoading] = useState(false);
  const [evidences, setEvidences] = useState([]);
  const [originalEvidences, setOriginalEvidences] = useState([]);
  const [subTasks, setSubTasks] = useState([]);
  const [taskComments, setTaskComments] = useState([]);
  const [selectedSubTab, setSelectedSubTab] = useState('Comments');
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [originalTaskDetails, setOriginalTaskDetails] = useState({});
  const [isEditKey, setEditKey] = useState('');
  const [searchedJob, setSearchedJob] = useState('');
  const [addComment, setAddComment] = useState('');
  const [priorityTooltipOpen, setPriorityTooltipOpen] = useState(false);
  const [useTimeline, setUseTimeline] = useState(false);
  const [isTimelineChanged, setIsTimelineChanged] = useState(false);
  const [formsData, setFormsData] = useState([]);

  const {
    id: task_id,
    engagement: { id: original_engagement_id },
  } = taskDetails;
  const { engagement, progress } = selectedTask || {};
  const { id: engagement_id, property, workitems } = engagement || {};
  const { status } = progress || {};
  const { id: workitem_id } = (workitems && workitems[0]) || {};
  const { id: property_id } = property || {};

  const beforeEvidence = (evidences || []).filter(evidence => evidence.timeline === 'BEFORE') || [];
  const duringEvidence = (evidences || []).filter(evidence => evidence.timeline === 'DURING') || [];
  const afterEvidence = (evidences || []).filter(evidence => evidence.timeline === 'AFTER') || [];
  const isEvidencesUpdate = isEqual(evidences, originalEvidences);

  const fetchTaskDetails = () => {
    setLoading(true);
    dispatch(getTaskDetails({ id: task_id }))
      .then(data => {
        setSelectedTask(data);
        setOriginalTaskDetails(data);
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_TASK_DETAILS') });
        setSelectedTask(taskDetails);
        setOriginalTaskDetails(taskDetails);
      })
      .finally(() => setLoading(false));
  };

  const fetchTaskEvidences = () => {
    setLoading(true);
    dispatch(getTaskEvidences({ task_id: task_id }))
      .then(data => {
        setEvidences(data);
        setOriginalEvidences(data);
        const is_timeline = (data || []).some(item => item.timeline === ('DURING' || 'AFTER'));
        setUseTimeline(is_timeline || false);
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_TASK_EVIDENCE') });
        setEvidences([]);
        setOriginalEvidences([]);
      })
      .finally(() => setLoading(false));
  };

  const fetchTaskSubTasks = () => {
    setLoading(true);
    dispatch(getTaskSubTasks({ task_id: task_id }))
      .then(data => {
        setSubTasks(data);
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_SUB_TASKS') });
        setSubTasks([]);
      })
      .finally(() => setLoading(false));
  };

  const fetchComments = () => {
    setLoading(true);
    dispatch(getTaskComments({ engagement_id: engagement_id, params: { task_id: task_id } }))
      .then(data => {
        const { content } = data || [];
        setTaskComments(content);
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_NOTES_COMMENTS') }))
      .finally(() => setLoading(false));
  };

  const fetchForms = () => {
    dispatch(getEvidenceConfigs({ params: { evidence_type: 'FORM' } }))
      .then(data => {
        setFormsData(data);
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_TASK_EVIDENCE') });
        setFormsData([]);
      })
      .finally(() => {});
  };

  const fetchData = () => {
    fetchTaskDetails();
    fetchTaskEvidences();
    fetchTaskSubTasks();
  };

  useEffect(() => {
    if (task_id) {
      setLoading(true);
      fetchData();
    }
  }, [task_id]);

  useEffect(() => {
    if (isEditKey === 'evidence') {
      fetchForms();
    }
  }, [isEditKey]);

  useEffect(() => {
    if (original_engagement_id) {
      fetchComments();
    }
  }, [original_engagement_id]);

  const gotToJobdetails = () => {
    setSideModal(initSideModal);
    navigate(`/properties/property/${property_id}/workitems/${workitem_id}`);
  };

  const onAddComment = () => {
    setLoading(true);
    const request = {
      note: addComment,
      task: {
        id: task_id,
      },
    };
    dispatch(addTaskComment({ engagement_id: engagement_id, request: request }))
      .then(() => {
        fetchComments();
        dispatch(addToast({ error: false, text: t('COMMENT_ADDED_SUCCESSFULLY'), id: nanoid() }));
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_ADDING_COMMENT'), id: nanoid() }))
      .finally(() => setLoading(false));
  };

  const getStatusStyles = status => {
    switch (status) {
      case 'COMPLETE':
        return {
          color: theme.success_500,
          background: theme.success_50,
        };
      case 'BLOCKED':
        return {
          color: theme.primary_500,
          background: theme.primary_50,
        };
      case 'STARTED':
        return {
          color: theme.purple_500,
          background: theme.purple_50,
        };
      default:
        return {
          color: theme.natural_600,
          background: theme.natural_150,
        };
    }
  };

  const StatusLabel = ({ data, isMainContent = false, size = 'medium' }) => {
    const { color, background } = useMemo(() => getStatusStyles(data?.value, theme), [data?.value, theme]);

    if (isMainContent) {
      return (
        <label
          style={{
            background,
            color,
            borderRadius: '50px',
          }}
          onClick={() => setTooltipOpen(true)}
          className={classNames(
            'select-label flex items-center justify-between px-3 text-center gap-1 w-fit-content',
            size === 'large' ? 'h-28px font-14' : 'h-24px font-12',
          )}>
          <label style={{ color }} className={classNames('inter-500-text', size === 'large' ? 'font-14' : 'font-12')}>
            {data?.label}
          </label>
          <IconContainer
            Icon={ArrowDownIcon}
            iconColor={color}
            backgroundColor={background}
            iconContainerClassname="p-0"
            iconWidth={14}
            iconHeight={14}
          />
        </label>
      );
    } else {
      return (
        <label
          style={{
            background,
            color,
            borderRadius: '50px',
          }}
          className={classNames(
            'flex items-center justify-center px-3 text-center w-full gap-1 fw-500',
            size === 'large' ? 'h-28px font-14' : 'h-24px font-12',
          )}>
          {data?.label}
        </label>
      );
    }
  };

  const TaskDetailsFileUpload = ({ type, file }) => {
    const { media, name } = file || {};
    const { url } = media || {};
    const { t } = useTranslation();
    const [onFile, setOnFile] = useState(false);

    const onAddFiles = addedFiles => {
      const newFiles = addedFiles?.map(file => ({
        file_data: file,
        name: file.name,
        size: file.size,
        type: file.type,
        url: URL.createObjectURL(file),
        id: nanoid(),
      }));
      setEvidences(prevItems =>
        prevItems.map(fileItem =>
          fileItem.id === file?.id ? { ...fileItem, media: (newFiles && newFiles[0]) || {} } : fileItem,
        ),
      );
    };

    const onFileDrop = e => {
      e.preventDefault();
      onAddFiles([...e.dataTransfer?.files]);
      setOnFile(false);
    };

    const onFileAdd = e => {
      e.preventDefault();
      onAddFiles([...e?.target?.files]);
      e.target.value = null;
    };

    return (
      <TaskFileUploadWrapper className="flex-column row-gap-6 overflow-hidden">
        <div className="flex-1 overflow-scroll">
          <div
            className={classNames(
              'pxy-2 border-dotted radius-1_5 items-center justify-between',
              onFile ? 'on-droparea' : 'off-droparea',
            )}
            onDragLeave={e => {
              e.preventDefault();
              setOnFile(false);
            }}
            onDragOver={e => {
              e.preventDefault();
              setOnFile(true);
            }}
            onDrop={onFileDrop}>
            <p className="flex justify-between items-center w-full font-16 inter-400-text">
              <div className="flex items-center">
                {url ? (
                  <img className={classNames('radius-1_5 w-32px h-32px')} src={url} />
                ) : (
                  <div className="icon-border border">
                    <IconContainer
                      Icon={type}
                      iconColor="natural_300"
                      backgroundColor="transparent"
                      iconContainerClassname="p-2"
                      iconWidth={16}
                      iconHeight={16}
                      className="p-3 border"
                    />
                  </div>
                )}
                <span className="ml-2 natural-700-text mr-1 font-14 text-ellipsis">{name}</span>
              </div>

              <p className="flex">
                <input type="file" id="file-upload-input" style={{ display: 'none' }} onChange={onFileAdd} />
                <UploadLabelWrapper>
                  {media ? (
                    <Button
                      fontSize="12px"
                      size="average"
                      width="auto"
                      height="24px"
                      borderRadius="100px"
                      className="primary specified-width px-4 py-2_5 ml-3"
                      label={t('DOWNLOAD')}
                    />
                  ) : fromActivity ? (
                    <Button
                      fontSize="12px"
                      size="average"
                      width="auto"
                      height="24px"
                      borderRadius="100px"
                      className="grey-150 specified-width px-4 py-2_5 ml-3"
                      label={t('NOT_UPLOADED')}
                    />
                  ) : (
                    <label htmlFor="file-upload-input" className="image-upload-button primary-text cursor">
                      {t('UPLOAD')}
                    </label>
                  )}
                </UploadLabelWrapper>
              </p>
            </p>
          </div>
        </div>
      </TaskFileUploadWrapper>
    );
  };

  const transformData = data => {
    return (data || []).map(item => {
      const defautValue = {
        name: item?.name,
        evidence_category: null,
        evidence_type: item?.evidence_type,
        form: item?.form || null,
        timeline: useTimeline ? item?.timeline : 'BEFORE' || 'BEFORE',
        is_required: true,
        tag: null,
      };
      return defautValue;
    });
  };

  const onUpdate = async data => {
    const label = capitalize(formatText(isEditKey, ' '));
    const updatedTask = data || selectedTask;
    const formattedEvidence = transformData(evidences);
    if (
      updatedTask?.name === originalTaskDetails?.name &&
      updatedTask?.description === originalTaskDetails?.description &&
      updatedTask?.engagement?.id === originalTaskDetails?.engagement?.id &&
      updatedTask?.assignee?.id === originalTaskDetails?.assignee?.id &&
      updatedTask?.due_date === originalTaskDetails?.due_date &&
      updatedTask?.progress?.status === originalTaskDetails?.progress?.status &&
      updatedTask?.priority === originalTaskDetails?.priority &&
      isEvidencesUpdate &&
      !isTimelineChanged
    ) {
      return setEditKey(null);
    } else {
      setLoading(true);
      try {
        const request = {
          ...updatedTask,
          name: updatedTask?.name,
          description: updatedTask?.description,
          engagement: {
            id: updatedTask?.engagement?.id,
          },
          service: { id: updatedTask?.service?.id ? updatedTask?.service?.id : '' },
          assignee: updatedTask?.assignee?.id ? { id: updatedTask?.assignee?.id } : null,
          due_date: updatedTask?.due_date,
          priority: updatedTask?.priority,
          evidence: formattedEvidence || [],
          estimated: updatedTask?.estimated?.duration ? updatedTask?.estimated : null,
          ...(parent_task_id && {
            parent: {
              id: parent_task_id,
            },
          }),
        };
        const updateValue = await dispatch(updateTask({ id: task_id, request }));
        dispatch(addToast({ error: false, text: `${label} updated successfully` }));
        setEditKey(null);
        if (!isEvidencesUpdate) {
          fetchTaskEvidences();
        }

        if (updateValue && originalTaskDetails) {
          fetchData();
          onSuccess && onSuccess();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(
          addToast({ error: true, text: error?.response?.data?.error_description || t('ERROR_WHILE_UPDATE_TASK') }),
        );
      }
    }
  };

  const onStatusUpdate = async progressData => {
    if (progressData?.status === originalTaskDetails?.progress?.status) {
      return setEditKey(null);
    } else {
      setLoading(true);
      try {
        const request = {
          progress: {
            start_date: selectedTask?.progress?.start_date || null,
            completed_date: selectedTask?.progress?.completed_date || null,
            status: progressData?.status || null,
            assignee: selectedTask?.assignee?.id ? { id: selectedTask?.assignee?.id } : null,
            priority: selectedTask?.priority || null,
          },
        };
        const updateValue = await dispatch(updateTaskProgress({ id: task_id, request }));
        dispatch(addToast({ error: false, text: t('STATUS_UPDATED_SUCCESSFULLY') }));
        setEditKey(null);

        if (updateValue && originalTaskDetails) {
          fetchData();
          onSuccess && onSuccess();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(
          addToast({ error: true, text: error?.response?.data?.error_description || t('ERROR_WHILE_UPDATE_TASK') }),
        );
      }
    }
  };

  const CustomSingleValue = ({ data, ...rest }) => {
    const { name, property } = data || {};
    const { formatted_address } = property || {};
    return (
      <components.SingleValue {...rest} className="">
        <div className="w-full ">
          <div className="flex-column">
            <span className="inter-400-text natural-900-text font-14 line-height-20 text-ellipsis mr-2">{name}</span>
            <span className="inter-400-text natural-500-text font-12 line-height-20 text-ellipsis">
              {formatted_address}
            </span>
          </div>
        </div>
      </components.SingleValue>
    );
  };

  const CustomOption = props => {
    const { innerProps, data } = props;
    const { name, property } = data || {};
    const { formatted_address } = property || {};

    return (
      <div className="w-full px-4 py-3 cursor border-bottom" {...innerProps}>
        <div className="flex-column">
          <span className="inter-400-text natural-900-text font-14 line-height-20 text-ellipsis mr-2">{name}</span>
          <span className="inter-400-text natural-500-text font-12 line-height-20 text-ellipsis">
            {formatted_address}
          </span>
        </div>
      </div>
    );
  };

  const PropertyJobOwners = ({ fromActivity = false }) => {
    const { t } = useTranslation();

    const [searchedOwner, setSearchedOwner] = useState('');

    const CustomOwnerOption = ({ data, innerProps, innerRef, isSelected }) => {
      const owner = fromActivity ? data?.user : data;
      return (
        <div className="flex items-center px-4 py-3 border-bottom cursor" ref={innerRef} {...innerProps}>
          <Avatar variant="medium" avatar={owner} backgroundColorIndex={0} />
          <label className="inter-400-text natural-900-text font-14 ml-2 flex-1">{owner?.name}</label>
        </div>
      );
    };

    const SingleOwnerOption = ({ data, ...rest }) => {
      const owner = fromActivity ? data?.user : data;
      return (
        <components.SingleValue {...rest} className="">
          <div className="flex items-center cursor">
            <Avatar variant="medium" avatar={owner} backgroundColorIndex={0} />
            <label className="inter-400-text natural-900-text font-14 ml-2 flex-1">{owner?.name}</label>
          </div>
        </components.SingleValue>
      );
    };

    return (
      <div className="flex justify-end w-full">
        <div className="w-full">
          <SearchableDropdown
            className="px-2"
            key={`owner_${engagement_id}`}
            inputValue={searchedOwner}
            onInputChange={setSearchedOwner}
            placeholder={t('UNASSIGNED')}
            isCustomSearchable={true}
            isMulti={false}
            openMenuOnFocus
            value={selectedTask?.assignee}
            onChange={option => {
              setSelectedTask({ ...selectedTask, assignee: option });
            }}
            getOptionValue={option => (fromActivity ? option?.user?.id : option?.id)}
            defaultAdditional={{
              page: 0,
              // defaultOptions: fromActivity ? resources : [],
              fetchFunction: !fromActivity ? getEngagementAccess : [],
              pageable: false,
              payload: { id: selectedTask?.engagement?.id },
            }}
            customStyle={{
              menu: { right: 0 },
              control: {
                minHeight: 28,
                borderColor: '#E5E5E5',
                cursor: 'pointer',
              },
              valueContainer: { display: 'flex', padding: '0' },
              dropdownIndicator: {
                svg: {
                  width: '15px',
                  height: '15px',
                  color: '#A3A3A3',
                },
              },
            }}
            customComponent={{ Option: CustomOwnerOption, SingleValue: SingleOwnerOption }}
          />
        </div>
      </div>
    );
  };

  const handleInputFocus = event => {
    setTimeout(() => {
      event.target.select();
    }, 0);
  };

  const ref = useOnclickOutside(() => {
    if (isEditKey) {
      setEditKey(null);
      onUpdate();
    }
  });

  const editInputContent = (key, value, label, type) => {
    return (
      <div className="flex-column w-full input-container relative">
        <div className={classNames('flex items-center')} ref={ref}>
          {key === 'description' ? (
            <textarea
              defaultValue={value}
              placeholder={t('ADD_SOME_TASK_DESCRIPTION')}
              className="inter-400-text natural-900-text overflow-auto input w-full textarea px-2 py-1 h-full job-input border"
              style={{ maxHeight: '260px' }}
              autoFocus
              onFocus={handleInputFocus}
              onChange={({ target: { value } }) => setSelectedTask({ ...selectedTask, [key]: value })}
            />
          ) : key === 'engagement' ? (
            <SearchableDropdown
              className="mx-2"
              inputValue={searchedJob}
              onInputChange={setSearchedJob}
              placeholder={t('SEARCH_TYPING_TO_SEE_SOME_RESULTS')}
              isSearchable={true}
              isCustomSearchable={false}
              loadOptionsOnMenuOpen={true}
              isMulti={false}
              openMenuOnFocus
              value={value}
              onChange={option => {
                const valueData = { ...selectedTask, [key]: option };
                onUpdate(valueData);
                setEditKey(null);
                setSelectedTask({ ...selectedTask, [key]: option });
              }}
              getOptionValue={option => option?.value}
              defaultAdditional={{
                page: 0,
                fetchFunction: getJobs,
                pageable: true,
              }}
              customStyle={{
                control: {
                  minHeight: 44,
                },
                menu: { zIndex: 9999 },
              }}
              customComponent={{ Option: CustomOption, SingleValue: CustomSingleValue }}
            />
          ) : key === 'assignee' ? (
            <PropertyJobOwners
              selectedJob={selectedTask?.engagement}
              engagement_id={selectedTask?.engagement ? selectedTask?.engagement?.id : engagement_id}
            />
          ) : key === 'due_date' ? (
            <DateSelectorElement
              className={'justify-between gap-3 mx-2 w-fit-content task-date-field'}
              setSelectedDate={value => {
                const valueData = { ...selectedTask, due_date: value };
                onUpdate(valueData);
                setEditKey(null);
                setSelectedTask({ ...selectedTask, due_date: value });
              }}
              wrapperClassName="date-selector-wrapper"
              selectedDate={selectedTask?.due_date || ''}
              placeholder={t('SELECT_DATE')}
              isCalendarIcon={false}
              minStartDate={moment().subtract(1, 'day').startOf('day').unix()}
            />
          ) : key === 'status' ? (
            <CustomTooltip
              wrapperClassName="status-tooltip"
              tooltipClassname="custom-scrollbar thin-scrollbar"
              id={`task-status-tooltip-${selectedTask?.id}`}
              place="bottom"
              clickable
              isOpen={tooltipOpen}
              onBlurTooltip={() => setTooltipOpen(false)}
              hidden={!tooltipOpen}
              noArrow={true}
              content={statusOptions
                ?.filter(statusVal => statusVal?.value !== status)
                ?.map((data, index) => (
                  <div
                    key={data?.label}
                    onClick={e => {
                      onStatusUpdate({ status: data?.value });
                      setEditKey(null);
                      setSelectedTask({ ...selectedTask, progress: { status: data?.value } });
                      setTooltipOpen(false);
                    }}
                    className="px-4 py-2 font-14 border-bottom cursor proposal-config">
                    <StatusLabel data={data} />
                  </div>
                ))}>
              <div onClick={() => setTooltipOpen(!tooltipOpen)} className={classNames('radius-1_5 w-auto cursor ')}>
                <StatusLabel
                  data={statusOptions?.find(item => item?.value == selectedTask?.progress?.status)}
                  isMainContent={true}
                  size="large"
                />
              </div>
            </CustomTooltip>
          ) : key === 'priority' ? (
            <CustomTooltip
              wrapperClassName="status-tooltip"
              tooltipClassname="custom-scrollbar thin-scrollbar"
              id={`priority-tooltip`}
              place="bottom"
              clickable
              isOpen={priorityTooltipOpen}
              onBlurTooltip={() => setPriorityTooltipOpen(false)}
              hidden={!priorityTooltipOpen}
              noArrow={true}
              content={priorityOptions
                ?.filter(priority => priority?.value !== selectedTask?.priority)
                ?.map((data, index) => (
                  <div
                    key={data?.label}
                    onClick={() => {
                      const valueData = { ...selectedTask, [key]: data?.value };
                      onUpdate(valueData);
                      setEditKey(null);
                      setSelectedTask({ ...selectedTask, [key]: data?.value });
                      setPriorityTooltipOpen(false);
                    }}
                    className="px-4 py-3 font-14 border-bottom cursor proposal-config">
                    <Status
                      withDot={false}
                      withDottedBorder={false}
                      status={data?.value}
                      icon={true}
                      statusText={capitalizeFirstLetterOfWords(formatText(data?.label))}
                    />
                  </div>
                ))}>
              <div
                onClick={() => setPriorityTooltipOpen(!priorityTooltipOpen)}
                className="flex items-center radius-1_5 cursor px-1 py-0_5 hover-edit">
                <Status
                  withDot={false}
                  withDottedBorder={false}
                  status={selectedTask?.priority}
                  icon={true}
                  statusText={capitalizeFirstLetterOfWords(formatText(selectedTask?.priority))}
                />
              </div>
            </CustomTooltip>
          ) : (
            <input
              className="inter-400-text natural-900-text one-line input w-full px-2 py-1 h-auto job-input border"
              defaultValue={value}
              type={type}
              autoFocus
              onFocus={handleInputFocus}
              onChange={({ target: { value } }) => setSelectedTask({ ...selectedTask, [key]: value })}
            />
          )}
        </div>
      </div>
    );
  };

  const handleSelectChange = (id, value) => {
    const newItems = evidences.map(item => (item.id === id ? { ...item, evidence_type: value } : item));
    setEvidences(newItems);
  };

  const handleNameChange = (id, value, isForm = false) => {
    const { id: idssss, ...updatedData } = value;
    const newItems = evidences.map(item =>
      item.id === id
        ? {
            ...item,
            ...(isForm ? updatedData : { name: value }),
          }
        : item,
    );
    setEvidences(newItems);
  };

  const removeItem = id => {
    setEvidences(evidences.filter(item => item.id !== id));
  };

  return (
    <TaskDetailsWrapper className="flex flex-column h-full w-full overflow-scroll">
      <SkeletonTransition
        loading={loading}
        setLoading={setLoading}
        loaderClassName={'item flex items-center justify-center flex-1 mb-6'}
        height={'100%'}
        containerClassName="line-height-1 h-full w-full">
        <div className="flex items-center justify-center py-5 px-6 border-bottom justify-between">
          <div className="flex w-full items-center justify-between">
            {isJobDetailsTask && <label className="font-20 inter-600-text">{t('TASK_DETAILS')}</label>}
            <LeftArrow height={24} width={24} className="cursor" onClick={() => setSideModal(initSideModal)} />
          </div>
          <Menu
            iconClassName="rotate-90"
            place="bottom-left"
            menuList={[
              {
                withIcon: true,
                icon: DeleteIcon,
                iconClassName: 'natural-700-text',
                name: t('DELETE_TASK'),
                onClick: () => {},
                permission: '',
              },
            ]}
          />
        </div>
        <div className="flex flex-column flex-1 overflow-scroll py-6">
          <div className="flex gap-2 w-full px-6 items-center">
            <div className="flex gap-2 w-full items-center">
              {isEditKey === 'STATUS' ? (
                <div className="flex">{editInputContent('status', selectedTask?.name, t('STATUS'), 'text')}</div>
              ) : (
                <div
                  onClick={() => {
                    setEditKey('STATUS');
                    setTooltipOpen(true);
                  }}
                  className="radius-1_5 cursor py-0_5">
                  <StatusLabel
                    data={statusOptions?.find(item => item?.value == status)}
                    isMainContent={true}
                    size="large"
                  />
                </div>
              )}
              {status !== 'COMPLETE' && (
                <div
                  className="border radius-100 h-28px w-28px flex items-center justify-center"
                  onClick={() => {
                    onStatusUpdate({ status: 'COMPLETE' });
                    setEditKey(null);
                    setSelectedTask({ ...selectedTask, status: 'COMPLETE' });
                  }}>
                  <IconContainer
                    Icon={CheckDoneIcon}
                    iconColor="natural_400"
                    backgroundColor="transparent"
                    iconContainerClassname="cursor"
                    iconWidth={14}
                    iconHeight={14}
                    className="p-3 border"
                  />
                </div>
              )}
              <Button
                label={t('GO_TO_JOB_DETAILS')}
                className="specified-width px-2"
                bgColor="transparent"
                borderColor={theme.primary_500}
                color={theme.primary_500}
                height="25px"
                size="medium"
                fontSize="12px"
                afterIcon={<RightArrowIcon />}
                onClick={gotToJobdetails}
              />
            </div>
            <IconContainer
              Icon={CrossIcons}
              iconColor="natural_400"
              backgroundColor="natural_100"
              iconContainerClassname="cursor radius-full"
              iconWidth={20}
              iconHeight={20}
              className="border"
              onClick={() => setSideModal(initSideModal)}
            />
          </div>
          <div className="flex flex-column items-start w-full radius-1_5 flex-1 px-6 overflow-auto custom-scrollbar thin-scrollbar mt-4 gap-6">
            <div className="w-full">
              {isEditKey === 'NAME' ? (
                editInputContent('name', selectedTask?.name, t('NAME'), 'text')
              ) : (
                <label
                  onClick={() => setEditKey('NAME')}
                  className="flex radius-1_5 cursor px-1 py-0_5 w-full hover-edit font-20 inter-600-text">
                  {selectedTask?.name}
                </label>
              )}
              {isEditKey === 'DESCRIPTION' ? (
                <div className="mt-2">
                  {editInputContent('description', selectedTask?.description, t('DESCRIPTION'))}
                </div>
              ) : (
                <p
                  onClick={() => setEditKey('DESCRIPTION')}
                  className="inter-400-text font-14 natural-400-text mt-2 radius-1_5 cursor px-1 py-0_5 w-full hover-edit text-ellipsis">
                  {selectedTask?.description || t('NO_DESCRIPTION_HERE')}
                </p>
              )}
            </div>
            {!fromActivity && !isJobDetailsTask && (
              <div className="pt-6 border-top w-full">
                {isEditKey === 'JOB' ? (
                  editInputContent('engagement', selectedTask?.engagement, t('JOB'))
                ) : !selectedTask?.engagement ? (
                  <div
                    onClick={() => setEditKey('JOB')}
                    className="inter-400-text natural-400-text line-height-20 font-14 radius-1_5 cursor px-1 py-0_5 hover-edit">
                    No job selected
                  </div>
                ) : (
                  <div
                    onClick={() => setEditKey('JOB')}
                    className="flex flex-column radius-1_5 cursor px-1 py-0_5 hover-edit">
                    <p className="inter-500-text natural-700-text line-height-20 font-16 text-ellipsis">
                      {selectedTask?.engagement?.name}
                    </p>
                    <p className="inter-400-text natural-400-text line-height-20 font-14 text-ellipsis">
                      {selectedTask?.engagement?.property?.formatted_address}
                    </p>
                  </div>
                )}
              </div>
            )}
            <div className="w-full flex flex-column gap-3 border-top pt-6">
              <div className="flex items-center justify-between">
                <p className="inter-400-text font-14 natural-400-text w-30">{t('ASSIGNEE')}</p>
                {isEditKey === 'ASSIGNEE' ? (
                  <div className="flex items-center w-full justify-end">
                    {editInputContent('assignee', selectedTask?.assignee, t('ASSIGNEE'))}
                  </div>
                ) : (
                  <div
                    onClick={() => setEditKey('ASSIGNEE')}
                    className="flex items-center radius-1_5 cursor px-1 py-0_5 hover-edit">
                    {selectedTask?.assignee?.name ? (
                      <div className="flex items-center col-gap-1 one-line">
                        <Avatar avatar={selectedTask?.assignee} variant="medium" />
                        <span className="inter-400-text natural-900-text one-line flex-1">
                          {selectedTask?.assignee?.name}
                        </span>
                      </div>
                    ) : (
                      <label className="inter-400-text line-height-20 font-14 radius-1_5 cursor px-1 py-0_5 hover-edit natural-400-text">
                        {t('NO_ASSIGNEE')}
                      </label>
                    )}
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between">
                <p className="inter-400-text font-14 natural-400-text w-30">{t('DUE_DATE')}</p>
                {isEditKey === 'DUE_DATE' ? (
                  <div className="flex items-center justify-end">
                    {editInputContent('due_date', selectedTask?.engagement, t('DUE_DATE'))}
                  </div>
                ) : (
                  <label
                    onClick={() => setEditKey('DUE_DATE')}
                    className={classNames(
                      'inter-400-text line-height-20 font-14 radius-1_5 cursor px-1 py-0_5 hover-edit',
                      !selectedTask?.due_date && 'natural-400-text',
                    )}>
                    {selectedTask?.due_date
                      ? moment.unix(Number(selectedTask?.due_date)).format('DD MMM')
                      : 'No due date'}
                  </label>
                )}
              </div>
              <div className="flex items-center justify-between">
                <p className="inter-400-text font-14 natural-400-text w-30">{t('PRIORITY')}</p>
                {isEditKey === 'PRIORITY' ? (
                  <div className="flex items-center justify-end">
                    {editInputContent('priority', selectedTask?.priority, t('PRIORITY'))}
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setEditKey('PRIORITY');
                      setPriorityTooltipOpen(true);
                    }}
                    className="flex items-center radius-1_5 cursor px-1 py-0_5">
                    <Status
                      withDot={false}
                      withDottedBorder={false}
                      status={selectedTask?.priority}
                      icon={true}
                      statusText={capitalizeFirstLetterOfWords(formatText(selectedTask?.priority))}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="w-full border-top border-bottom py-6 relative">
              <div className="flex justify-between items-center mb-5">
                <p className="inter-400-text font-14 natural-400-text">
                  {t('EVIDENCE')} {`(${(evidences || []).length})`}
                </p>
                <IconContainer
                  Icon={isEditKey === 'evidence' ? CloseIcon : EditIcon}
                  iconColor="primary_500"
                  backgroundColor="primary_50"
                  iconContainerClassname="cursor radius-full pxy-1-5"
                  iconWidth={16}
                  iconHeight={16}
                  className="p-3 border"
                  onClick={() => setEditKey(isEditKey === 'evidence' ? null : 'evidence')}
                />
              </div>
              {isEditKey === 'evidence' ? (
                <>
                  <div className="flex justify-between items-center w-full relative p-1 pl-2 bg-natural-100 radius-1 mt-2 mb-8">
                    <div className="flex items-center gap-2">
                      <Checkbox
                        is_checked_done={true}
                        checked={useTimeline}
                        onChange={() => {
                          setUseTimeline(!useTimeline);
                          setIsTimelineChanged(!isTimelineChanged);
                        }}
                        id="use-timeline-checkbox"
                        variant="small"
                      />
                      <label htmlFor="use-timeline-checkbox" className="inter-400-text natural-900-text font-12">
                        {t('USE_TIMELINE')}
                      </label>
                    </div>
                  </div>
                  {useTimeline ? (
                    <div className="flex flex-column gap-8">
                      <div className="flex flex-column gap-4">
                        <label className="inter-600-text font-14 natural-900-text line-height-20">{t('BEFORE')}</label>
                        {beforeEvidence.length > 0 && (
                          <div className="flex flex-column gap-2">
                            {(beforeEvidence || []).map((item, index) => {
                              const isFirstIndex = index === 0;
                              return (
                                <div key={index}>
                                  <div className="flex gap-3">
                                    <div className="flex-column">
                                      {isFirstIndex && (
                                        <label className="inter-400-text natural-900-text font-14 mb-1">
                                          {t('TYPE')}
                                        </label>
                                      )}
                                      <Dropdown
                                        className="w-auto"
                                        onChange={e => {
                                          const { value } = e || {};
                                          handleSelectChange(item?.id, value);
                                        }}
                                        options={evidenceOptions}
                                        placeholder={t('SELECT_TYPE')}
                                        value={evidenceOptions.find(evidence => item.evidence_type === evidence.value)}
                                        customStyle={{
                                          control: {
                                            width: '140px',
                                          },
                                          valueContainer: {
                                            paddingRight: '0px !important',
                                            margin: '0px !important',
                                          },
                                        }}
                                      />
                                    </div>
                                    {item?.evidence_type === 'FORM' ? (
                                      <div className="flex-column w-full">
                                        {isFirstIndex && (
                                          <label className="inter-400-text natural-900-text font-14 mb-1">
                                            {t('FORM_TYPE')}
                                          </label>
                                        )}
                                        <Dropdown
                                          onChange={option => {
                                            handleNameChange(item.id, option, true);
                                          }}
                                          options={formsData}
                                          getOptionLabel={option => {
                                            const { name } = option;
                                            return name;
                                          }}
                                          getOptionValue={option => option.id}
                                          placeholder={'select form'}
                                          className="w-full"
                                          value={(formsData || []).find(
                                            from_type => from_type?.form?.id === item?.form?.id,
                                          )}
                                        />
                                      </div>
                                    ) : (
                                      <div className="flex-column">
                                        {isFirstIndex && (
                                          <label className="inter-400-text natural-900-text font-14 mb-1">
                                            {t('NAME')}
                                          </label>
                                        )}
                                        <InputElement
                                          placeholder={t('FILE_NAME_GOES_HERE')}
                                          onChange={e => handleNameChange(item.id, e)}
                                          value={item?.name}
                                        />
                                      </div>
                                    )}
                                    <IconContainer
                                      Icon={CloseIcon}
                                      iconColor="natural_500"
                                      backgroundColor={'white'}
                                      iconContainerClassname={classNames('p-0 cursor', isFirstIndex && 'mt-4')}
                                      iconWidth={20}
                                      iconHeight={20}
                                      onClick={() => removeItem(item.id)}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        <div
                          className="flex items-center gap-1"
                          onClick={() => {
                            setEvidences([...evidences, { id: nanoid(), name: '', timeline: 'BEFORE', isNew: true }]);
                          }}>
                          <IconContainer
                            backgroundColor="transparent"
                            Icon={PlusIcon}
                            iconWidth={12}
                            iconHeight={12}
                            iconColor="primary_500"
                          />
                          <label className="inter-500-text primary-text cursor font-14 line-height-20">
                            {t('ADD_EVIDENCE')}
                          </label>
                        </div>
                      </div>
                      <div className="flex flex-column gap-4">
                        <label className="inter-600-text font-14 natural-900-text line-height-20">{t('DURING')}</label>
                        {duringEvidence.length > 0 && (
                          <div className="flex flex-column gap-2">
                            {(duringEvidence || []).map((item, index) => {
                              const isFirstIndex = index === 0;
                              return (
                                <div key={index}>
                                  <div className="flex gap-3">
                                    <div className="flex-column">
                                      {isFirstIndex && (
                                        <label className="inter-400-text natural-900-text font-14 mb-1">
                                          {t('TYPE')}
                                        </label>
                                      )}
                                      <Dropdown
                                        className="w-auto"
                                        onChange={e => {
                                          const { value } = e || {};
                                          handleSelectChange(item?.id, value);
                                        }}
                                        options={evidenceOptions}
                                        placeholder={t('SELECT_TYPE')}
                                        value={evidenceOptions.find(evidence => item.evidence_type === evidence.value)}
                                        customStyle={{
                                          control: {
                                            width: '140px',
                                          },
                                          valueContainer: {
                                            paddingRight: '0px !important',
                                            margin: '0px !important',
                                          },
                                        }}
                                      />
                                    </div>
                                    {item?.evidence_type === 'FORM' ? (
                                      <div className="flex-column w-full">
                                        {isFirstIndex && (
                                          <label className="inter-400-text natural-900-text font-14 mb-1">
                                            {t('FORM_TYPE')}
                                          </label>
                                        )}
                                        <Dropdown
                                          onChange={option => {
                                            handleNameChange(item.id, option, true);
                                          }}
                                          options={formsData}
                                          getOptionLabel={option => {
                                            const { name } = option;
                                            return name;
                                          }}
                                          getOptionValue={option => option.id}
                                          placeholder={'select form'}
                                          className="w-full"
                                          value={(formsData || []).find(
                                            from_type => from_type?.form?.id === item?.form?.id,
                                          )}
                                        />
                                      </div>
                                    ) : (
                                      <div className="flex-column">
                                        {isFirstIndex && (
                                          <label className="inter-400-text natural-900-text font-14 mb-1">
                                            {t('NAME')}
                                          </label>
                                        )}
                                        <InputElement
                                          placeholder={t('FILE_NAME_GOES_HERE')}
                                          onChange={e => handleNameChange(item.id, e)}
                                          value={item?.name}
                                        />
                                      </div>
                                    )}
                                    <IconContainer
                                      Icon={CloseIcon}
                                      iconColor="natural_500"
                                      backgroundColor={'white'}
                                      iconContainerClassname={classNames('p-0 cursor', isFirstIndex && 'mt-4')}
                                      iconWidth={20}
                                      iconHeight={20}
                                      onClick={() => removeItem(item.id)}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        <div
                          className="flex items-center gap-1"
                          onClick={() => {
                            setEvidences([...evidences, { id: nanoid(), name: '', timeline: 'DURING', isNew: true }]);
                          }}>
                          <IconContainer
                            backgroundColor="transparent"
                            Icon={PlusIcon}
                            iconWidth={12}
                            iconHeight={12}
                            iconColor="primary_500"
                          />
                          <label className="inter-500-text primary-text cursor font-14 line-height-20">
                            {t('ADD_EVIDENCE')}
                          </label>
                        </div>
                      </div>
                      <div className="flex flex-column gap-4">
                        <label className="inter-600-text font-14 natural-900-text line-height-20">{t('AFTER')}</label>
                        {afterEvidence.length > 0 && (
                          <div className="flex flex-column gap-2">
                            {(afterEvidence || []).map((item, index) => {
                              const isFirstIndex = index === 0;
                              return (
                                <div key={index}>
                                  <div className="flex gap-3">
                                    <div className="flex-column">
                                      {isFirstIndex && (
                                        <label className="inter-400-text natural-900-text font-14 mb-1">
                                          {t('TYPE')}
                                        </label>
                                      )}
                                      <Dropdown
                                        className="w-auto"
                                        onChange={e => {
                                          const { value } = e || {};
                                          handleSelectChange(item?.id, value);
                                        }}
                                        options={evidenceOptions}
                                        placeholder={t('SELECT_TYPE')}
                                        value={(evidenceOptions || []).find(
                                          evidence => item.evidence_type === evidence.value,
                                        )}
                                        customStyle={{
                                          control: {
                                            width: '140px',
                                          },
                                          valueContainer: {
                                            paddingRight: '0px !important',
                                            margin: '0px !important',
                                          },
                                        }}
                                      />
                                    </div>
                                    {item?.evidence_type === 'FORM' ? (
                                      <div className="flex-column w-full">
                                        {isFirstIndex && (
                                          <label className="inter-400-text natural-900-text font-14 mb-1">
                                            {t('FORM_TYPE')}
                                          </label>
                                        )}
                                        <Dropdown
                                          onChange={option => {
                                            handleNameChange(item.id, option, true);
                                          }}
                                          options={formsData}
                                          getOptionLabel={option => {
                                            const { name } = option;
                                            return name;
                                          }}
                                          getOptionValue={option => option.id}
                                          placeholder={'select form'}
                                          className="w-full"
                                          value={(formsData || []).find(
                                            from_type => from_type?.form?.id === item?.form?.id,
                                          )}
                                        />
                                      </div>
                                    ) : (
                                      <div className="flex-column">
                                        {isFirstIndex && (
                                          <label className="inter-400-text natural-900-text font-14 mb-1">
                                            {t('NAME')}
                                          </label>
                                        )}
                                        <InputElement
                                          placeholder={t('FILE_NAME_GOES_HERE')}
                                          onChange={e => handleNameChange(item.id, e)}
                                          value={item?.name}
                                        />
                                      </div>
                                    )}
                                    <IconContainer
                                      Icon={CloseIcon}
                                      iconColor="natural_500"
                                      backgroundColor={'white'}
                                      iconContainerClassname={classNames('p-0 cursor', isFirstIndex && 'mt-4')}
                                      iconWidth={20}
                                      iconHeight={20}
                                      onClick={() => removeItem(item.id)}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        <div
                          className="flex items-center gap-1"
                          onClick={() => {
                            setEvidences([...evidences, { id: nanoid(), name: '', timeline: 'AFTER', isNew: true }]);
                          }}>
                          <IconContainer
                            backgroundColor="transparent"
                            Icon={PlusIcon}
                            iconWidth={12}
                            iconHeight={12}
                            iconColor="primary_500"
                          />
                          <label className="inter-500-text primary-text cursor font-14 line-height-20">
                            {t('ADD_EVIDENCE')}
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>
                        {(evidences || []).map((item, index) => {
                          return (
                            <div key={index} style={{ marginBottom: '10px' }}>
                              <div className="flex gap-3">
                                <div className="flex-column">
                                  {index === 0 && (
                                    <label className="inter-400-text natural-900-text font-14 mb-1">{t('TYPE')}</label>
                                  )}
                                  <Dropdown
                                    className="w-auto"
                                    onChange={e => {
                                      const { value } = e || {};
                                      handleSelectChange(item?.id, value);
                                    }}
                                    options={evidenceOptions}
                                    placeholder={t('SELECT_TYPE')}
                                    value={evidenceOptions.find(evidence => item.evidence_type === evidence.value)}
                                    customStyle={{
                                      control: {
                                        width: '140px',
                                      },
                                      valueContainer: {
                                        paddingRight: '0px !important',
                                        margin: '0px !important',
                                      },
                                    }}
                                  />
                                </div>
                                {item?.evidence_type === 'FORM' ? (
                                  <div className="flex-column w-full">
                                    {index === 0 && (
                                      <label className="inter-400-text natural-900-text font-14 mb-1">
                                        {t('FORM_TYPE')}
                                      </label>
                                    )}
                                    <Dropdown
                                      onChange={option => {
                                        handleNameChange(item?.id, option, true);
                                      }}
                                      options={formsData}
                                      getOptionLabel={option => {
                                        const { name } = option;
                                        return name;
                                      }}
                                      getOptionValue={option => option?.id}
                                      placeholder={'select form'}
                                      className="w-full"
                                      value={(formsData || []).find(
                                        from_type => from_type?.form?.id === item?.form?.id,
                                      )}
                                    />
                                  </div>
                                ) : (
                                  <div className="flex-column">
                                    {index === 0 && (
                                      <label className="inter-400-text natural-900-text font-14 mb-1">
                                        {t('NAME')}
                                      </label>
                                    )}
                                    <InputElement
                                      placeholder={t('FILE_NAME_GOES_HERE')}
                                      onChange={e => handleNameChange(item.id, e)}
                                      value={item?.name}
                                    />
                                  </div>
                                )}
                                <IconContainer
                                  Icon={CloseIcon}
                                  iconColor="natural_500"
                                  backgroundColor={'white'}
                                  iconContainerClassname={classNames('p-0 cursor', index === 0 && 'mt-4')}
                                  iconWidth={20}
                                  iconHeight={20}
                                  onClick={() => removeItem(item.id)}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        className="flex items-center gap-1"
                        onClick={() => {
                          setEvidences([...evidences, { id: nanoid(), name: '', isNew: true }]);
                        }}>
                        <IconContainer
                          backgroundColor="transparent"
                          Icon={PlusIcon}
                          iconWidth={12}
                          iconHeight={12}
                          iconColor="primary_500"
                        />
                        <label className="inter-500-text primary-text cursor font-14 line-height-20">
                          {t('ADD_EVIDENCE')}
                        </label>
                      </div>
                    </>
                  )}
                  {(!isEvidencesUpdate || isTimelineChanged) && (
                    <div className="w-full flex justify-end">
                      <div className="flex justify-center items-end radius-3 job-update-action mt-1 border pxy-2 gap-2_5 absolute">
                        <span
                          className="flex justify-center items-center cross-icon border radius-100 cursor"
                          onClick={() => {
                            setEvidences(originalEvidences);
                            setEditKey(null);
                            const is_timeline = (originalEvidences || []).some(
                              item => item.timeline === ('DURING' || 'AFTER'),
                            );
                            setUseTimeline(is_timeline || false);
                          }}>
                          <CrossIcon color="#171717" />
                        </span>
                        <span
                          className="flex justify-center items-center right-icon border radius-100 cursor"
                          onClick={() => onUpdate()}>
                          <RightIcon color="#fff" />
                        </span>
                      </div>
                    </div>
                  )}
                </>
              ) : (evidences || []).length > 0 ? (
                <div className="flex flex-column gap-5 mt-6">
                  {useTimeline ? (
                    <>
                      <div className="flex flex-column gap-4">
                        <p className="inter-600-text font-14 natural-900-text line-height-20">{t('BEFORE')}</p>
                        <div className="flex flex-column gap-3">
                          {beforeEvidence.map(file => (
                            <TaskDetailsFileUpload
                              key={file?.id}
                              type={file?.evidence_type === 'IMAGE' ? ImageContent : FileIcon}
                              file={file}
                            />
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-column gap-4">
                        <p className="inter-600-text font-14 natural-900-text line-height-20">{t('DURING')}</p>
                        <div className="flex flex-column gap-3">
                          {duringEvidence.map(file => (
                            <TaskDetailsFileUpload
                              key={file?.id}
                              type={file?.evidence_type === 'IMAGE' ? ImageContent : FileIcon}
                              file={file}
                            />
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-column gap-4">
                        <p className="inter-600-text font-14 natural-900-text line-height-20">{t('AFTER')}</p>
                        <div className="flex flex-column gap-3">
                          {afterEvidence.map(file => (
                            <TaskDetailsFileUpload
                              key={file?.id}
                              type={file?.evidence_type === 'IMAGE' ? ImageContent : FileIcon}
                              file={file}
                            />
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    (evidences || []).map(file => (
                      <TaskDetailsFileUpload
                        key={file?.id}
                        type={file?.evidence_type === 'IMAGE' ? ImageContent : FileIcon}
                        file={file}
                      />
                    ))
                  )}
                </div>
              ) : (
                <p className="inter-400-text font-14 natural-400-text text-center mt-3">{t('NO_EVIDENCE_AVAILABLE')}</p>
              )}
            </div>

            {(subTasks || []).length > 0 && (
              <div className="w-full">
                <p className="inter-400-text font-14 natural-400-text mb-5">
                  {t('SUBTASKS')} {`(${(subTasks || []).length})`}
                </p>
                {(subTasks || []).length > 0 && (
                  <div className="flex flex-column gap-3 mt-3">
                    {subTasks.map((task, index) => (
                      <SubTaskItem key={index} task={task} index={index} subTasks={subTasks} />
                    ))}
                  </div>
                )}
              </div>
            )}

            <div className="w-full">
              <div className="flex justify-between items-center w-full">
                <div className="flex gap-5 w-full">
                  {commentTabs.map(tab => (
                    <div
                      key={tab.key}
                      onClick={() => setSelectedSubTab(tab.name)}
                      className={classNames(
                        'px-6 py-4_5 cursor w-full',
                        tab.name === selectedSubTab && 'selected-tab',
                      )}>
                      <label className="inter-500-text natural-700-text text-center flex justify-center w-full">
                        {tab.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-column flex-1 w-full pt-4">
                {selectedSubTab === 'Comments' &&
                  ((taskComments || []).length > 0 ? (
                    <div className="flex-column gap-4">
                      {taskComments &&
                        taskComments.map((comment, index) => {
                          const { created, note, id } = comment;
                          const { user, date } = created || {};
                          const { name } = user || {};
                          const lastIndex = taskComments?.length - 1;
                          const currentMoment = moment();
                          const isToday = moment.unix(date).isSame(currentMoment, 'day');
                          return (
                            <div
                              key={id}
                              className={classNames(
                                'flex gap-2 w-full pb-0',
                                lastIndex !== index && 'border-bottom pb-4',
                              )}>
                              <div className="flex items-start">
                                {user?.name && (
                                  <div className="flex items-center col-gap-1 one-line">
                                    <Avatar avatar={user} variant="medium" backgroundColorIndex={index} />
                                  </div>
                                )}
                              </div>
                              <div className="w-full flex-column items-center gap-1 one-line">
                                <div className="flex justify-between w-full">
                                  <label className="inter-500-text natural-900-text one-line">{name} commented</label>
                                  <label className="inter-500-text natural-500-text">
                                    {date ? moment.unix(date).format(isToday ? 'HH:MM' : 'DD MMM, YYYY') : '-'}
                                  </label>
                                </div>
                                <label className="inter-400-text natural-500-text w-full one-line">{note}</label>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <NoData
                      title={t('NO_COMMENTS')}
                      description={t('NO_COMMENTS_HERE')}
                      className="mt-12"
                      EmptyIcon={EmptyIcon}
                      iconClassName="relative"
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full border-top py-5 px-6">
          <div className="flex items-start gap-3 relative">
            <div className="flex items-start">
              <div className={classNames('flex items-center justify-center cursor user-logo-wrapper')}>
                <label className="bold-text white-text font-10">{getUserInitials(user?.user)}</label>
              </div>
            </div>
            <div className="w-full relative comment-content px-4 py-2 border radius-1_5">
              <textarea
                placeholder={t('TYPING_COMMENT')}
                onChange={e => {
                  setAddComment(e.target.value);
                }}
                className="inter-400-text natural-900-text overflow-auto w-full textarea"
              />
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <IconContainer
                    Icon={ImageAdd}
                    iconColor="natural_400"
                    backgroundColor="transparent"
                    iconContainerClassname="cursor p-2"
                    iconWidth={16}
                    iconHeight={16}
                    className="p-3 border"
                  />
                  <IconContainer
                    Icon={Attachment}
                    iconColor="natural_400"
                    backgroundColor="transparent"
                    iconContainerClassname="cursor p-2"
                    iconWidth={16}
                    iconHeight={16}
                    className="p-3 border"
                  />
                </div>
                <div className="flex">
                  <Button
                    bgColor={theme.primary_500}
                    borderColor={theme.primary_500}
                    color={theme.white}
                    label={t('SEND')}
                    className="px-3 py-1_5"
                    onClick={onAddComment}
                    disabled={!addComment.length}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SkeletonTransition>
    </TaskDetailsWrapper>
  );
};

const UploadLabelWrapper = styled.div`
  .image-upload-button {
    padding: 2px 12px;
    background-color: #2496ff;
    color: white;
    border-radius: 50px;
    font-size: 12px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

const TaskFileUploadWrapper = styled.div`
  .off-droparea {
    border-color: ${({ theme }) => theme.natural_300};
  }
  .on-droparea {
    border-color: ${({ theme }) => theme.primary_500};
    background-color: ${({ theme }) => theme.primary_50};
  }
  .icon-border {
    border-radius: 4px;
    width: 32px;
    height: 32px;
    .p-2 {
      padding: 8px;
    }
    img {
      border-radius: 4px;
    }
  }
`;

export default TaskDetails;
