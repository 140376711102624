import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { useError } from '../../hooks/useError';
import { getProductSpecifications } from '../../store/features/productsSlice';
import Button from '../common/button/button';
import IconContainer from '../common/icon-container';
import InputElement from '../common/input';
import MenuV2 from '../common/menu/menu-v2';
import Dropdown from '../common/select-dropdown';
import TableHeader from '../common/table-header';

const specificationHeaders = [
  { name: 'CLASSIFICATION', key: 'CLASSIFICATION' },
  { name: 'SPECIFICATION', key: 'SPECIFICATION' },
  { name: 'UNIT', key: 'UNIT' },
  { name: '', key: 'MENU' },
];

const classificationOptions = [
  { value: 'WIDTH', label: 'Width' },
  { value: 'HEIGHT', label: 'Height' },
  { value: 'DEPTH', label: 'Depth' },
  { value: 'SPEED', label: 'Speed' },
  { value: 'CAPACITY', label: 'Capacity' },
  { value: 'POWER_OUT', label: 'Power Out' },
];

const unitOptions = [
  { value: 'KG', label: 'kg' },
  { value: 'CM', label: 'cm' },
  { value: 'WATT', label: 'watt' },
  { value: 'KWH', label: 'kWh' },
];

const ProductSpecificationItem = ({
  specification,
  showBorderTop,
  isEdit,
  onEdit = () => {},
  onDelete = () => {},
  onCancel,
  onSave,
  setSpecifications,
  is_product_owner,
}) => {
  const { classification, specification: spec, units, id } = specification;

  const classificationOption = classificationOptions.find(option => option.value === classification) || null;
  const unitOption = unitOptions.find(option => option.value === units) || null;

  if (isEdit) {
    return (
      <div className={classNames('data-container items-center relative', showBorderTop && 'border-top')}>
        <Dropdown
          options={classificationOptions}
          value={classificationOption}
          onChange={option => setSpecifications(id, { classification: option.value })}
          placeholder="Select"
          menuPortalTarget={document.body}
          customStyle={{
            control: { height: '32px', minHeight: '32px', borderRadius: '6px' },
            dropdownIndicator: { margin: '0' },
          }}
        />
        <InputElement
          value={spec}
          type="number"
          showNumberArrows={false}
          placeholder={'Enter'}
          onChange={value => setSpecifications(id, { specification: value })}
          style={{ height: '32px', minHeight: '32px', borderRadius: '6px', padding: '2px 16px' }}
        />
        <Dropdown
          options={unitOptions}
          value={unitOption}
          onChange={option => setSpecifications(id, { units: option.value })}
          placeholder="Select"
          menuPortalTarget={document.body}
          customStyle={{
            control: { height: '32px', minHeight: '32px', borderRadius: '6px' },
            dropdownIndicator: { margin: '0' },
          }}
        />
        <div className="flex justify-center" />

        <div className="flex justify-center items-center pxy-2 radius-3 absolute bg-white col-gap-2 action-container z-1">
          <IconContainer
            Icon={CrossIcon}
            backgroundColor="white"
            iconContainerClassname="radius-full border cursor"
            iconColor="natural_400"
            onClick={() => onCancel(id)}
          />
          <IconContainer
            Icon={RightIcon}
            backgroundColor="primary_500"
            iconContainerClassname="radius-full cursor"
            iconColor="white"
            onClick={() => onSave()}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'data-container items-center',
        showBorderTop && 'border-top',
        !is_product_owner && 'specifications-grid',
      )}>
      <label className="inter-500-text">{classificationOption?.label || ''}</label>
      <label className="inter-500-text">{spec}</label>
      <label className="inter-500-text">{unitOption?.label || ''}</label>
      {is_product_owner && (
        <div className="flex justify-center">
          <MenuV2
            iconClassname="rotate-90"
            menuList={[
              { name: 'Edit', onClick: () => onEdit(id), permission: 'PRODUCTS_MANAGE' },
              { name: 'Delete', onClick: () => onDelete(id), permission: 'PRODUCTS_MANAGE' },
            ].filter(Boolean)}
          />
        </div>
      )}
    </div>
  );
};

const ProductSpecification = ({
  setIsEditSpecification,
  setSpecifications,
  specifications,
  isUpdateSpecification,
  is_product_owner,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();

  const { showErrorToast } = useError();

  const [addSpecifications, setAddSpecifications] = useState(false);

  const specificationHeaders = [
    { name: 'CLASSIFICATION', key: 'CLASSIFICATION' },
    { name: 'SPECIFICATION', key: 'SPECIFICATION' },
    { name: 'UNIT', key: 'UNIT' },
    ...(is_product_owner ? [{ name: '', key: 'MENU' }] : []),
  ];

  const fetchProductSpecifications = () => {
    dispatch(getProductSpecifications({ product_id: id }))
      .then(data => {
        setSpecifications(data.map(spec => ({ ...spec, isEdit: false, isNew: false })));
      })
      .catch(error => {
        showErrorToast({ error, default_message: 'Failed to fetch product specifications' });
      });
  };

  useEffect(() => {
    fetchProductSpecifications();
  }, [id, isUpdateSpecification]);

  const validateSpecification = specification => {
    const { classification, specification: spec, units } = specification;
    return classification && spec && units;
  };

  const handleSave = () => {
    const updatedSpecifications = specifications.map(item => {
      if (item.isEdit) {
        if (!validateSpecification(item)) {
          showErrorToast({ default_message: 'Classification, specifications, and unit are required' });
          return item;
        }
        return { ...item, isEdit: false, ...(!item.isNew && { isUpdate: true }) };
      }
      return item;
    });

    setSpecifications(updatedSpecifications);
    setIsEditSpecification(true);
  };

  const updateSpecificationEdit = (id, updates) => {
    setSpecifications(prev => {
      const index = prev.findIndex(item => item.id === id);
      if (index !== -1) {
        const updatedEditList = [...prev];
        updatedEditList[index] = { ...updatedEditList[index], ...updates };
        return updatedEditList;
      } else {
        return [...prev, { id, ...updates }];
      }
    });
  };

  const handleEdit = id => {
    const item = specifications.find(spec => spec.id === id);
    if (item) {
      updateSpecificationEdit(id, {
        isEdit: true,
        originalValues: { classification: item.classification, specification: item.specification, units: item.units },
      });
    }
  };

  const handleCancel = id => {
    setSpecifications(prev =>
      prev
        .map(item => {
          if (item.id === id) {
            if (item.isNew) {
              return null;
            }
            return { ...item, ...item.originalValues, isEdit: false };
          }
          return item;
        })
        .filter(Boolean),
    );
  };

  const handleDelete = id => {
    setSpecifications(prev => {
      return prev
        .map(item => {
          if (item.id === id) {
            if (item.isNew) {
              return null;
            }
            return { ...item, isDelete: true, isUpdate: false };
          }
          return item;
        })
        .filter(Boolean);
    });
    setIsEditSpecification(true);
  };

  return (
    <ProductSpecificationWrapper>
      <div>
        {specifications?.length > 0 || addSpecifications ? (
          <div className="w-full card radius-1_5 border overflow-scroll mt-4">
            <div className="flex-column w-fit-content min-w-full h-full">
              <TableHeader
                headers={specificationHeaders}
                headerClassName={classNames(!is_product_owner && 'specifications-grid')}
              />
              {specifications
                .filter(specification => !specification.isDelete)
                .map(specification => (
                  <ProductSpecificationItem
                    key={specification.id}
                    specification={specification}
                    showBorderTop={specifications.length > 1}
                    isEdit={specification.isEdit}
                    onEdit={id => handleEdit(id)}
                    onDelete={id => handleDelete(id)}
                    onCancel={id => handleCancel(id)}
                    onSave={handleSave}
                    setSpecifications={updateSpecificationEdit}
                    is_product_owner={is_product_owner}
                  />
                ))}
              {is_product_owner && (
                <div className="add-new-specification pxy-4 border-top">
                  <label
                    className="inter-400-text primary-500-text flex items-center cursor"
                    onClick={() => updateSpecificationEdit(nanoid(), { isEdit: true, isNew: true })}>
                    <IconContainer
                      Icon={AddIcon}
                      iconColor="primary_500"
                      backgroundColor="transparent"
                      iconContainerClassname="p-0 mr-1"
                      iconHeight={14}
                      iconWidth={14}
                    />
                    {t('ADD_SPECIFICATION')}
                  </label>
                </div>
              )}
            </div>
          </div>
        ) : (
          !is_product_owner && (
            <label className="inter-400-text font-14 line-height-20 natural-400-text">No Specifications</label>
          )
        )}
        {specifications?.length === 0 && !addSpecifications && is_product_owner && (
          <Button
            label={t('ADD_SPECIFICATION')}
            icon={<AddIcon />}
            onClick={() => {
              updateSpecificationEdit(nanoid(), { isEdit: true, isNew: true });
              setAddSpecifications(true);
            }}
            size="medium"
            className="w-fit-content primary-500-text py-2_5 pr-4"
            bgColor={theme.white}
            borderColor={theme.white}
            color={theme.primary_500}
          />
        )}
      </div>
    </ProductSpecificationWrapper>
  );
};

const ProductSpecificationWrapper = styled.div`
  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) 40px;
    column-gap: 16px;
    padding: 16px;
  }

  .specifications-grid {
    grid-template-columns: minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr);
  }

  .action-container {
    right: 0;
    bottom: -34px;
    box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1), 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  }
`;

export default ProductSpecification;
